import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { CloseFab } from '../../elements/Fab/CloseFab'
import { SliderPagination } from '../../elements/SliderPagination'
import { getImageUrl } from '../../units/SmartImage/getImageUrl'
import * as styles from './styles'

const swipeConfidenceThreshold = 5000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const fadeInVariant = {
  enter: {
    y: 100,
    opacity: 0,
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 100,
    opacity: 0,
  },
}

const wrap = (min, max, v) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

export const LightBox = ({ initialIndex = 0, images, onClose }) => {
  const [[page, direction], setPage] = useState([initialIndex, 0])

  const imageIndex = wrap(0, images.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <>
      <CloseFab zIndex={101} bottom="25px" onClick={onClose} />
      <styles.Pagination>
        <SliderPagination
          count={images.length}
          onClick={index => setPage([index, index - page])}
          activeIndex={page}
        />
      </styles.Pagination>
      <styles.Container
        key="container"
        variants={fadeInVariant}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { duration: 0.3 },
          opacity: { duration: 0.3 },
        }}
      >
        <styles.PrevButton
          onClick={() => paginate(-1)}
          aria-label="Next image"
        />

        <styles.NextButton
          onClick={() => paginate(1)}
          aria-label="Previous image"
        />

        <styles.ItemCounter aria-live="polite" aria-atomic="true">
          {imageIndex + 1} / {images.length}
        </styles.ItemCounter>
        <AnimatePresence initial={false} custom={direction}>
          <styles.ItemWrap
            key={page}
            variants={variants}
            custom={direction}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 100, damping: 20 },
              opacity: { duration: 0.3 },
            }}
          >
            <styles.ItemOuter
              drag="x"
              dragConstraints={{ left: -100, right: 100 }}
              dragElastic={1}
              onDragEnd={(_, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x)

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1)
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1)
                }
              }}
            >
              <styles.Item
                src={getImageUrl(images[imageIndex], { width: 1500 })}
                alt={
                  (images[imageIndex].fields &&
                    images[imageIndex].fields.description) ||
                  'Lightbox image'
                }
              />
            </styles.ItemOuter>
          </styles.ItemWrap>
        </AnimatePresence>
      </styles.Container>
    </>
  )
}
