import React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

const SiteTitle = styled.h1`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`

const showHeadingOnThesePaths = [
  '/',
  '/accomodation',
  '/day-visit',
  '/restaurant',
  '/explore',
]

interface LogoProps {
  className?: string
}

export const Logo = ({ className }: LogoProps) => {
  const { asPath } = useRouter()
  return (
    <>
      {showHeadingOnThesePaths.includes(asPath) && (
        <SiteTitle>Blue Lagoon Iceland</SiteTitle>
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 158 39"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
        role="presentation"
        data-cy="logo"
      >
        <g fill="currentColor">
          <path d="M9 13.375c0 2.33-1.606 3.043-4.182 3.043-.553 0-1.135 0-1.605-.026-.277-.03-.5-.146-.5-.544v-5.656h2.162c2.906 0 4.124.765 4.124 2.9v.283zM2.713 2.793c0-.282.084-.367.308-.425.36-.084.97-.11 1.69-.11 2.13 0 3.18.68 3.18 2.556v.257C7.9 7.24 6.85 8 4.35 8H2.713V2.794zM8.34 8.795V8.77c1.492-.54 2.35-1.994 2.35-3.868v-.316C10.69 1.432 8.448.01 4.432.01 3.186.01 1.83.122.775.323.165.437 0 .633 0 1.317v16.296c0 .657.195.882.83.94 1.083.082 2.383.113 3.82.113 4.545 0 7.15-1.51 7.15-5.345v-.31c0-2.38-1.386-3.78-3.46-4.21z" />
          <path d="M23.418 15.8H18.54c-.612 0-.81-.193-.81-.747V.585c0-.44-.165-.585-.61-.585h-1.507C15.17 0 15 .144 15 .585v14.91c0 1.672.727 2.505 2.678 2.505h5.74c.47 0 .582-.14.582-.502V16.3c0-.385-.112-.5-.582-.5" />
          <path d="M39.393 0h-1.49c-.443 0-.61.15-.61.61v11.788c0 1.416-.22 2.546-.798 3.21-.637.724-1.574 1.072-3.01 1.072-1.353 0-2.344-.348-2.978-1.07-.61-.666-.803-1.796-.803-3.212V.608c0-.458-.166-.608-.582-.608h-1.54C27.16 0 27 .15 27 .61v11.93c0 2.174.495 3.883 1.57 4.896C29.676 18.478 31.14 19 33.486 19c2.347 0 3.892-.55 4.94-1.564C39.476 16.423 40 14.77 40 12.54V.61c0-.46-.164-.61-.607-.61" />
          <path d="M55 17.472v-1.14c0-.392-.085-.53-.595-.53H48.11c-.764 0-1.106-.25-1.19-.975-.17-1.332-.2-2.978-.2-5.005h6.58c.485 0 .57-.166.57-.528v-1.14c0-.39-.085-.532-.57-.532h-6.58c0-1.64.06-3.277.143-4.367.056-.748.483-1.026 1.274-1.026h6.297c.48 0 .566-.17.566-.56V.56c0-.416-.085-.56-.566-.56h-6.52c-2.468 0-3.517.64-3.745 3.033C44 4.566 44 6.93 44 8.403V9.49c0 1.64.085 3.753.23 5.31.223 2.616 1.216 3.2 3.655 3.2h6.52c.51 0 .595-.14.595-.528" />
          <path d="M67.675 18h5.737c.472 0 .588-.14.588-.502V16.3c0-.385-.116-.5-.588-.5h-4.874c-.615 0-.81-.193-.81-.747V.585c0-.44-.166-.585-.612-.585h-1.502c-.45 0-.614.144-.614.585v14.91c0 1.672.722 2.505 2.675 2.505" />
          <path d="M81.808 10.82c.81-2.667 1.706-5.592 2.605-8.118h.174c.898 2.526 1.793 5.45 2.606 8.12h-5.385zM92 17.668c0-.25-.116-.586-.205-.866-1.593-5.56-3.5-11.43-5.152-15.91-.29-.774-.58-.89-1.535-.89h-1.22c-.953 0-1.242.116-1.53.89-1.652 4.48-3.562 10.35-5.156 15.91-.084.28-.202.616-.202.867 0 .22.147.33.576.33h1.392c.665 0 .84-.06.983-.59.33-1.25.76-2.84 1.19-4.29h6.72c.44 1.44.87 3.03 1.19 4.28.15.53.32.58.99.58h1.39c.44 0 .58-.11.58-.333z" />
          <path d="M106 17.425V9.143c0-.37-.135-.543-.524-.543h-4.404c-.442 0-.582.114-.582.543V10.4c0 .43.14.51.582.51h2.23v5.117c0 .342-.11.456-.415.514-.605.12-1.542.17-2.506.17-3.49 0-4.59-1.54-4.59-6.85v-.71c0-5.31 1.32-6.86 4.68-6.86 1.49 0 2.84.35 3.97.8.39.18.64.12.75-.25l.25-.8c.14-.4.2-.6.2-.71 0-.17-.11-.31-.33-.4-1.267-.54-2.95-.91-4.682-.91-2.173 0-3.86.43-5.07 1.4C93.8 2.8 93 5.26 93 8.83v1.31c0 6.43 1.624 8.86 7.38 8.86 1.71 0 3.61-.29 4.99-.66.52-.15.63-.38.63-.92" />
          <path d="M121.16 9.827c0 2.86-.084 4.598-1.01 5.685-.674.797-1.63 1.2-3.148 1.2-1.52 0-2.475-.403-3.15-1.2-.93-1.087-1.012-2.825-1.012-5.685V9.17c0-2.854.083-4.6 1.013-5.685.674-.8 1.63-1.2 3.15-1.2 1.516 0 2.473.4 3.147 1.2.926 1.086 1.01 2.83 1.01 5.685v.657zM117.003 0c-2.447 0-4.275.685-5.257 1.77C110.396 3.282 110 5.054 110 8.854v1.316c0 3.8.394 5.545 1.745 7.054.982 1.09 2.81 1.776 5.257 1.776 2.446 0 4.274-.687 5.254-1.776 1.352-1.51 1.744-3.254 1.744-7.053V8.86c0-3.8-.392-5.572-1.744-7.086-.98-1.084-2.808-1.77-5.254-1.77z" />
          <path d="M138.16 9.827c0 2.86-.084 4.598-1.012 5.685-.674.797-1.63 1.2-3.147 1.2s-2.47-.403-3.15-1.2c-.92-1.087-1.01-2.825-1.01-5.685V9.17c0-2.854.09-4.6 1.02-5.685.68-.8 1.64-1.2 3.15-1.2s2.48.4 3.15 1.2c.93 1.086 1.01 2.83 1.01 5.685v.657zM134 0c-2.446 0-4.273.685-5.26 1.77-1.35 1.513-1.74 3.285-1.74 7.085v1.316c0 3.8.39 5.55 1.74 7.06.987 1.09 2.814 1.78 5.26 1.78 2.446 0 4.275-.68 5.258-1.77 1.347-1.51 1.742-3.25 1.742-7.05V8.86c0-3.8-.395-5.57-1.742-7.086C138.275.686 136.446 0 134 0z" />
          <path d="M157.245.262h-1.332c-.44 0-.58.147-.58.598V14.6h-.137l-7.174-13.537c-.303-.572-.556-.8-1.303-.8h-1.47c-.64 0-.83.146-.83.8V18.07c0 .483.14.596.58.596h1.33c.44 0 .58-.113.58-.597V4.32h.13l7.17 13.57c.3.54.55.768 1.3.768h1.47c.63 0 .83-.14.83-.766V.86c0-.45-.14-.598-.58-.598" />
          <path d="M49.754 28h-.522c-.145 0-.232.085-.232.29v10.42c0 .205.102.29.247.29h.507c.144 0 .246-.085.246-.29V28.29c0-.205-.102-.29-.246-.29" />
          <path d="M56.06 29.638c.44-.447 1.07-.71 1.938-.71.833 0 1.615.23 2.33.643.152.09.255.09.34-.05l.288-.42c.07-.11.07-.23-.085-.31-.83-.49-1.73-.78-2.92-.78-1.07 0-1.94.27-2.58.78-1.03.83-1.36 2.24-1.36 4.29v.88c0 1.87.21 3.14.94 3.986.63.71 1.58 1.075 3.23 1.075.9 0 1.86-.2 2.59-.48.22-.08.27-.18.22-.364l-.12-.364c-.05-.18-.2-.215-.39-.15-.73.266-1.48.43-2.29.43-1.052 0-1.733-.262-2.175-.742-.63-.694-.75-1.67-.75-3.606v-.463c0-1.9.138-2.94.82-3.62" />
          <path d="M68.757 28h-3.73c-.665 0-1.086.102-1.394.374-.31.272-.47.714-.52 1.36-.08.97-.113 2.5-.113 3.315v.9c0 1.14.016 2.22.097 3.31.05.64.196 1.08.503 1.36.31.27.73.37 1.395.37h3.762c.195 0 .243-.09.243-.26v-.44c0-.16-.048-.26-.243-.26h-3.682c-.34 0-.535-.057-.664-.17-.13-.14-.17-.36-.19-.7-.06-.9-.06-1.84-.06-3.387H68c.193 0 .242-.1.242-.257v-.44c0-.17-.05-.256-.243-.256h-3.85c0-1.377.04-2.16.09-2.976.01-.34.06-.56.19-.695.13-.14.322-.225.663-.225h3.647c.196 0 .244-.1.244-.253v-.44c0-.17-.05-.255-.242-.255" />
          <path d="M77.737 38.048h-2.85c-.524 0-.68-.136-.68-.578v-9.18c0-.205-.105-.29-.298-.29h-.63c-.18 0-.28.085-.28.29v9.35c0 .935.49 1.36 1.59 1.36h3.14c.19 0 .26-.085.26-.22v-.512c0-.153-.07-.22-.27-.22" />
          <path d="M82.29 34.546c.28-1.02.596-2.092.877-3.06.18-.645.478-1.582.742-2.38h.19c.26.798.56 1.735.74 2.38.28.968.59 2.057.87 3.06h-3.43zm3.565-3.47c-.28-.883-.577-1.817-.874-2.6-.16-.425-.26-.476-.67-.476h-.59c-.41 0-.49.05-.66.476-.29.783-.61 1.717-.89 2.6-.76 2.398-1.52 5.033-2.14 7.636-.05.22.04.288.22.288h.66c.18 0 .285-.068.33-.272l.796-3.162H86c.28 1.037.545 2.075.81 3.162.05.204.13.272.33.272h.642c.197 0 .264-.1.215-.323-.628-2.6-1.386-5.203-2.13-7.6z" />
          <path d="M97.787 28h-.546c-.15 0-.21.085-.21.29v9.606h-.1l-3.98-9.37c-.15-.338-.27-.526-.7-.526h-.75c-.37 0-.5.102-.5.51v10.2c0 .205.06.29.22.29h.55c.15 0 .21-.085.21-.29v-9.57h.11l3.98 9.333c.15.34.27.527.7.527h.748c.373 0 .5-.102.5-.51v-10.2c0-.205-.06-.29-.22-.29" />
          <path d="M107.88 33.785c0 1.996-.306 2.95-.963 3.555-.598.554-1.21.72-2.513.72h-1.163c-.13 0-.18-.066-.18-.217v-8.618c0-.168.05-.235.19-.252.3-.033.77-.033 1.17-.033 1.21 0 2.12.25 2.68.938.55.672.8 1.61.8 3.37v.537zM104.146 28c-.89 0-1.195.017-1.793.084-.276.034-.352.17-.352.437v10.06c0 .29.11.42.37.42h2.21c3.2 0 4.43-1.54 4.43-5.08v-.84c0-2.06-.36-3.23-1.15-4-.81-.8-2.05-1.07-3.7-1.07z" />
        </g>
      </svg>
    </>
  )
}
