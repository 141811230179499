import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { RichTextRenderer } from '../richText/RichTextRenderer'
import { media } from '../utils/media'
import { CloseIcon } from './Icons/CloseIcon'
import { Type } from './Typography/Typography'

const documentOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Type preset="text">{children}</Type>,
  },
}

const documentOptionsTargetBlank = {
  renderNode: {
    ...documentOptions.renderNode,
    [INLINES.HYPERLINK]: (node, children) =>
      documentOptions.renderNode[INLINES.HYPERLINK](node, children, {
        target: '_blank',
      }),
  },
}

const styles = {
  Container: styled(motion.div)`
    align-items: flex-start;
    background: ${colors.white};
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) =>
      `${theme.spacing[1.5]} 0 ${theme.spacing[1.5]} ${theme.spacing[1.5]}`};

    & + & {
      margin-top: ${({ theme }) => theme.spacing[1]};
    }

    ${media.md(
      css`
        align-items: center;
        padding: ${({ theme }) =>
          `${theme.spacing[1]} ${theme.spacing[0.5]} ${theme.spacing[1]} ${theme.spacing[1.5]}`};
      `
    )}
  `,
  Content: styled.div``,
  CloseButton: styled.button`
    flex: 0 0 40px;
    align-items: center;
    cursor: pointer;
    display: flex;

    justify-content: center;

    padding: ${({ theme }) => theme.spacing[1]};
    padding-top: ${({ theme }) => theme.spacing[0.5]};
    margin-left: ${({ theme }) => theme.spacing[1.5]};
  `,
}

export const Notification = ({ id, content, targetBlank, onClose }) => {
  return (
    <styles.Container
      variants={{
        hide: {
          opacity: 0,
          y: 20,
        },
        show: { opacity: 1, y: 0 },
      }}
      initial="show"
      animate="show"
      exit="hide"
      transition={{ duration: 0.3 }}
    >
      <styles.Content>
        {!!content && (
          <RichTextRenderer
            document={content}
            customOptions={
              targetBlank ? documentOptionsTargetBlank : documentOptions
            }
          />
        )}
      </styles.Content>
      <styles.CloseButton onClick={() => onClose(id)} aria-label="close">
        <CloseIcon style={{ height: '14px', width: '14px' }} />
      </styles.CloseButton>
    </styles.Container>
  )
}
