import { css } from 'styled-components'

let maxScrollbarWidth = 0

const scrollbarWidth = () => {
  if (typeof window === 'undefined' || !window || !document) return 0

  const clientWidth = document.body ? document.body.clientWidth : 0
  const adjustedPadding = window.innerWidth - clientWidth || 0
  if (adjustedPadding > maxScrollbarWidth) {
    maxScrollbarWidth = adjustedPadding
  }

  return maxScrollbarWidth
}

// TODO: Should this be in bl-utils? (Trying to not have bl-utils depend on styled-components)
export const lockedScrollOffset = () =>
  css`
    html.scroll-lock & {
      right: ${scrollbarWidth()}px;
    }
  `
