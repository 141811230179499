import React from 'react'

export const ConfirmTick = ({ color = 'white', ...rest }) => (
  <svg
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M16.0098 25.1301L29.8598 41.9101L54.2698 10.1201"
        stroke={color}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M56.32 24.5C56.71 26.32 56.92 28.2 56.92 30.13C56.92 44.9 44.9 56.92 30.13 56.92C15.36 56.92 3.34 44.9 3.34 30.13C3.34 15.36 15.36 3.34 30.13 3.34C34.35 3.34 38.34 4.34 41.89 6.09L43.93 3.37C39.8 1.22 35.11 0 30.13 0C13.52 0 0 13.52 0 30.13C0 46.75 13.52 60.26 30.13 60.26C46.74 60.26 60.26 46.74 60.26 30.13C60.26 27.06 59.79 24.1 58.93 21.3L56.32 24.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="60.26" height="60.27" fill={color} />
      </clipPath>
    </defs>
  </svg>
)
