import React, { Component } from 'react'

import {
  elementHeight,
  elementWidth,
  elementY,
  lastValue,
  observe,
  viewportY,
} from '../../react-observe/lib/api'
import { Observer } from '../../react-observe/Observer'
import { getImageUrl } from './getImageUrl'

type SmartImageProps = {
  image?: any
  styles?: any
  defaultWidth?: number
  children: any
}

const backgroundHandler = (file, width, height) =>
  getImageUrl(file, {
    width,
    height,
  })
const background = file =>
  observe(file, elementWidth(), elementHeight(), backgroundHandler)

// This needs to be smarter for fast scrollers and 3G. E.g. a lower resolution image until element comes into view,
// then switch to higher resolution image. This might be tricky to implement "smoothly".
const shouldLoad = observe(
  elementY(-2),
  viewportY(1),
  lastValue(),
  (elementRunway, viewportBottom, didLoad) =>
    didLoad || elementRunway < viewportBottom
)

const lazyBackground = file =>
  observe(
    background(file),
    shouldLoad,
    (background, shouldLoad) => shouldLoad && background
  )

export class SmartImage extends Component<SmartImageProps> {
  renderChildren = imageUrl => {
    const { image, styles, defaultWidth = 1280, children } = this.props
    const hasImage = !!imageUrl
    imageUrl =
      imageUrl || getImageUrl(image, { width: defaultWidth, ssr: true })

    let props = imageUrl
    if (styles) {
      props = {
        style: { backgroundImage: `url(${imageUrl})` },
        className: !hasImage ? 'jsBG' : undefined,
      }
    }

    return children(props)
  }

  render() {
    const { image } = this.props
    return (
      <Observer value={lazyBackground(image)}>{this.renderChildren}</Observer>
    )
  }
}
