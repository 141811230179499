import React, { createContext, ReactNode, useEffect, useReducer } from 'react'

import { localStorage } from 'bl-utils/src/storage/localStorage'

import { availableCurrencies } from '../../constants/availableCurrencies'
import { currencyKey, getCurrencyInformation, Rates } from './currency'

type CurrencyProviderProps = {
  language?: 'is' | 'en'
  ratesToUse?: 'rates' | 'mcpRates'
  children?: ReactNode
}

type State = {
  countryCode: string
  currency: string
  exchangeRates: Rates | null
  isLoading: boolean
  retrievalReferenceNumber?: string
}

type Action =
  | { type: 'SET_CURRENCY_INFO'; payload: Partial<State> }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'CHANGE_CURRENCY'; payload: string }

const initialState: State = {
  countryCode: 'IS',
  currency: 'ISK',
  exchangeRates: null,
  isLoading: true,
}

const currencyReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CURRENCY_INFO':
      return { ...state, ...action.payload, isLoading: false }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'CHANGE_CURRENCY':
      return { ...state, currency: action.payload }
    default:
      return state
  }
}

const DEFAULT_CURRENCIES = {
  is: 'ISK',
  en: 'USD',
}

const DEFAULT_COUNTRY_CODE = {
  is: 'IS',
  en: 'US',
}

export const CurrencyContext = createContext<
  State & { changeCurrency: (currency: string) => void }
>({
  countryCode: '',
  currency: '',
  exchangeRates: null,
  isLoading: false,
  changeCurrency: () => {},
})

const useCurrencyState = ({
  language,
  ratesToUse,
}: {
  language: CurrencyProviderProps['language']
  ratesToUse: CurrencyProviderProps['ratesToUse']
}) => {
  const [state, dispatch] = useReducer(currencyReducer, {
    ...initialState,
    currency: DEFAULT_CURRENCIES[language],
    countryCode: DEFAULT_COUNTRY_CODE[language],
  })

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', payload: true })
    getCurrencyInformation()
      .then(([currencyInfo, exchangeRates]) => {
        dispatch({
          type: 'SET_CURRENCY_INFO',
          payload: {
            countryCode:
              currencyInfo.countryCode || DEFAULT_COUNTRY_CODE[language],
            currency: currencyInfo.currency || DEFAULT_CURRENCIES[language],
            exchangeRates: { ...exchangeRates[ratesToUse], ISK: 1 },
            retrievalReferenceNumber: exchangeRates.retrievalReferenceNumber,
          },
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: 'SET_LOADING', payload: false })
      })
  }, [language, ratesToUse])

  const changeCurrency = (newCurrency: string) => {
    const currencyToUse = availableCurrencies.find(
      curr => curr.ISO === newCurrency
    )
    if (currencyToUse) {
      localStorage.setItem(
        currencyKey,
        JSON.stringify({
          countryCode: state.countryCode,
          ISO: currencyToUse.ISO,
          timeCreated: new Date().toISOString(),
        })
      )
      dispatch({ type: 'CHANGE_CURRENCY', payload: newCurrency })
    }
  }

  return { state, changeCurrency }
}

export const CurrencyProvider = ({
  language = 'is',
  ratesToUse = 'rates',
  children,
}: CurrencyProviderProps) => {
  const { state, changeCurrency } = useCurrencyState({ language, ratesToUse })

  return (
    <CurrencyContext.Provider value={{ ...state, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  )
}
