import { getCookie } from '../cookies'
import { DataLayerObject, PageViewEvent } from './types/google-analytics-events'

export const triggerEvent = (event: DataLayerObject) => {
  try {
    const gaDebug = getCookie('gaDebug') === 'on'
    if (process.env.NODE_ENV === 'development' || gaDebug) {
      console.log('Google Analytics', event)
    } else {
      window.dataLayer = window.dataLayer || []
      if (Object.hasOwn(event, 'ecommerce'))
        window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push(event)
    }
  } catch (error) {
    console.error(error)
  }
}

/** Get page category from path. Ignore /is if language is Icelandic */
const getPageCategory = (language: 'is' | 'en') => {
  const [, ...path] = window.location.pathname.split('/')
  if (path[0] === '') return undefined
  return path[language === 'en' ? 0 : 1]
}

/** Get subpage category from path. Ignore /is if language is Icelandic */
const getSubPageCategory = (language: 'is' | 'en') => {
  const [, ...path] = window.location.pathname.split('/')
  if (path[1] === '') return undefined
  return path[language === 'en' ? 1 : 2]
}

export const triggerPageView = (
  event: Omit<
    PageViewEvent,
    'event' | 'pageTitle' | 'pageCategory' | 'pageSubCategory'
  > & { pageSubCategory?: string }
) => {
  if (typeof window !== 'undefined') {
    // Fix issue that sent previous pageTitle instead of new page title when user navigates between pages
    // Delay makes sure that window.document.title has updated before it is sent
    setTimeout(() => {
      const pageViewEvent: PageViewEvent = {
        event: 'globalpv',
        pageTitle: window.document.title,
        pageCategory: getPageCategory(event.language),
        pageSubCategory:
          event.pageSubCategory || getSubPageCategory(event.language),
        ...event,
      }
      triggerEvent(pageViewEvent)
    }, 0)
  }
}
