import React, {
  ComponentType,
  CSSProperties,
  ForwardedRef,
  forwardRef,
} from 'react'

import { StyledSection } from './styles'

type SectionProps = {
  children: React.ReactNode
  as?: string | ComponentType<any>
  config?: {
    fields: {
      isHeader?: unknown
      top?: unknown
      bottom?: unknown
      gradient?: unknown
      background?: unknown
      textColor?: unknown
      noHorizontalPadding?: unknown
      id?: unknown
    }
  }
  isHeader?: boolean
  top?: string | number | object
  bottom?: string | number | object
  gradient?: object
  gradientOffset?: number
  gradientStartsWhite?: boolean
  noHorizontalPadding?: boolean
  textColor?: unknown
  id?: string
  style?: CSSProperties
  minheight?: any
  hideOverflowX?: boolean
  background?: any
}

export const Section = forwardRef(
  (
    {
      children,
      as,
      config,
      isHeader: defaultIsHeader,
      top: defaultTop = { xs: 6, md: 9 },
      bottom: defaultBottom = { xs: 6, md: 9 },
      gradient: defaultGradient = {
        fields: {
          color1: 'transparent',
          color2: 'transparent',
          degrees: '90deg',
        },
      },
      noHorizontalPadding: defaultNoHorizontalPadding,
      textColor,
      id,
      hideOverflowX,
      ...props
    }: SectionProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const fields = config ? config.fields : {}
    const isHeader = fields.isHeader || defaultIsHeader
    const top = fields.top || defaultTop
    const bottom = fields.bottom || defaultBottom
    const gradient = fields.gradient || defaultGradient
    const background = fields.background
    const color = fields.textColor || textColor
    const noHorizontalPadding =
      fields.noHorizontalPadding || defaultNoHorizontalPadding
    const sectionID = id ?? fields?.id

    return (
      <StyledSection
        as={isHeader ? 'header' : as}
        isHeader={isHeader}
        top={top}
        bottom={bottom}
        gradient={gradient}
        noHorizontalPadding={noHorizontalPadding}
        background={background}
        textColor={color}
        ref={ref}
        id={sectionID}
        hideOverflowX={hideOverflowX}
        {...props}
      >
        {children}
      </StyledSection>
    )
  }
)

Section.displayName = 'Section'
