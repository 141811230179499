import React, { FC, useContext, useEffect, useState } from 'react'

import { colors } from 'bl-common/src/constants/colors'
import { BreadcrumbContext } from 'bl-common/src/context/BreadcrumbContext'
import {
  IPageConfig,
  IPageConfigFields,
} from 'bl-common/src/generated/contentful'
import { CssThemeVariables } from 'bl-common/src/styles/CssThemeVariables'
import { Footer } from 'bl-common/src/units/Footer/Footer'

import { Navigation } from './Navigation'
import { ScrollRestore } from './ScrollRestore'
import { SiteConfig } from './SiteConfig'

interface PageBaseProps {
  children: React.ReactNode
  config?: IPageConfig
  breadcrumb?: string
  scrollHeader?: boolean
  isFramed?: boolean
  footer?: any
  hideFooter?: boolean
  hasSubnavigation?: boolean
  configOverride?: Partial<IPageConfigFields>
}

const Page: FC<PageBaseProps> = ({
  config,
  configOverride = {},
  breadcrumb,
  scrollHeader,
  footer,
  hideFooter,
  hasSubnavigation,
  children,
}) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const { saveBreadcrumb } = useContext(BreadcrumbContext)
  const pageConfig = { ...config?.fields, ...configOverride }
  const {
    hasFooter = !hideFooter,
    hasAbsolutelyPositionedNavigation,
    hasWhiteNavigation,
    hideNavigation,
    isDarkMode,
  } = pageConfig

  const checkDarkMode = () => {
    const currConfig: Partial<IPageConfigFields> = config ? config.fields : {}
    const bodyClass = document.body.classList

    if (currConfig?.isDarkMode) {
      bodyClass.add('t-dark')
      // Customize color scheme and scrollbar for dark mode
      document.documentElement.style.setProperty('color-scheme', 'dark')
      document.documentElement.style.setProperty(
        '--color-scrollbar-thumb',
        colors.blueOnDark
      )
      document.documentElement.style.setProperty(
        '--color-scrollbar-track',
        colors.darkmodeOffset
      )
    } else {
      bodyClass.remove('t-dark')
      document.documentElement.style.removeProperty('color-scheme')
      document.documentElement.style.removeProperty('--color-scrollbar-thumb')
      document.documentElement.style.removeProperty('--color-scrollbar-track')
    }
  }

  const defaultScroll = () => {
    if (firstLoad) {
      setFirstLoad(false)
      return null
    }

    if (!scrollHeader) {
      return null
    }

    /*
    Commenting this code out because it seems like this isn't doing anything
    and it's causing console errors. Don't want to delete completely yet,
    just in case it turns out that there is a use case where this is needed.
    Also, when we move to NextJS 13, we want to use the built in scroll restoration
    instead as it performs better, so we won't need any of this then
    https://app.asana.com/0/1205240384235169/1206183637444920
    */

    // if (!nav) {
    //   return null
    // }

    // const { offsetHeight, offsetTop, offsetWidth } = nav

    // const isMobile = offsetWidth <= breakpoints.md - 1
    // const scrollRestoreAmount =
    //   isFramed && !isMobile
    //     ? offsetTop + offsetHeight - topicLineSize
    //     : offsetTop + offsetHeight

    // return [0, scrollRestoreAmount]
    return [0, 0]
  }

  useEffect(() => {
    if (breadcrumb && saveBreadcrumb) {
      saveBreadcrumb(breadcrumb)
    }

    checkDarkMode()

    return () => {
      const bodyClass = document.body.classList

      if (bodyClass.contains('t-dark')) {
        bodyClass.remove('t-dark')
        document.documentElement.style.removeProperty('color-scheme')
        document.documentElement.style.removeProperty('--color-scrollbar-thumb')
        document.documentElement.style.removeProperty('--color-scrollbar-track')
      }
    }
  }, [])

  return (
    <>
      <Navigation
        isAbsolutelyPositioned={!!hasAbsolutelyPositionedNavigation}
        isWhiteColored={!!hasWhiteNavigation || isDarkMode}
        hasSubnavigation={hasSubnavigation}
        hideNavigation={hideNavigation}
      />

      <ScrollRestore defaultScroll={defaultScroll} />

      <CssThemeVariables
        as="main"
        suppressHydrationWarning
        listMarkerColor={isDarkMode && colors.blueOnDark}
        linkColor={isDarkMode && colors.blueOnDark}
      >
        {children}
      </CssThemeVariables>

      {hasFooter && (
        <SiteConfig>
          {({ footer: configFooter }) => (
            <Footer footer={footer || configFooter} />
          )}
        </SiteConfig>
      )}
    </>
  )
}

export default Page
