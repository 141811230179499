import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  position: absolute;
  top: 0;
  width: 16px;
  right: 16px;
  bottom: 0;
  margin: auto;
  pointer-events: none;
`

export const CalendarIcon = () => (
  <Svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 22"
    width="24"
    height="17"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 5H2v15h22V5zM2 3a2 2 0 00-2 2v15a2 2 0 002 2h22a2 2 0 002-2V5a2 2 0 00-2-2H2zM4 0v3H2V0h2zM24 0v3h-2V0h2z"
      fill="#52A5B8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10H9V8h3v2zM17 10h-3V8h3v2zM22 10h-3V8h3v2zM12 14H9v-2h3v2zM17 14h-3v-2h3v2zM22 14h-3v-2h3v2zM12 18H9v-2h3v2zM7 14H4v-2h3v2zM7 18H4v-2h3v2zM17 18h-3v-2h3v2zM22 18h-3v-2h3v2z"
      fill="#52A5B8"
    />
  </Svg>
)
