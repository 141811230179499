export const availableCurrencies = [
  {
    symbol: '€',
    ISO: 'EUR',
    name: 'Euro',
  },
  {
    symbol: '$',
    ISO: 'USD',
    name: 'US Dollar',
  },
  {
    symbol: '£',
    ISO: 'GBP',
    name: 'Pound sterling',
  },
  {
    symbol: '',
    ISO: 'ISK',
    name: 'Icelandic Krona',
  },
]
