import React, { useState } from 'react'
import valid from 'card-validator'

import { StyledInput } from './Input/Input'

type CCInputProps = {
  id?: string
  name?: string
  value?: string
  placeholder?: string
  label?: string
  type?: string
  required?: boolean
  isRequired?: boolean
  hasError?: boolean
  disableEditing?: boolean
  onChange?: (name?: string) => any
  onFocus?: () => any
  // ...rest
}

const formatCardNumber = value => {
  const val = value.replace(/\D/g, '')
  const validated = valid.number(val)

  // default format 1111 1111 1111 1111
  let formatted = val
    .replace(/(\d{4})/, '$1 ')
    .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
    .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')

  if (validated.card) {
    const { type } = validated.card

    // amex format 4444 444444 4444
    if (type === 'american-express') {
      formatted = val
        .replace(/(\d{4})/, '$1 ')
        .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
    }
  }

  return formatted
}

export const formatExpiryDate = value => {
  const val = value.replace(/\D/g, '')

  return val.replace(/(\d{2})(\d{0,4})/, '$1 / $2')
}

export const CardNumberInput = ({
  id,
  name,
  value = '',
  placeholder,
  label,
  type = 'text',
  required = false,
  isRequired = false,
  hasError = false,
  disableEditing = false,
  onChange = () => {},
  onFocus = () => {},
  ...rest
}: CCInputProps) => {
  const [text, setText] = useState(value || '')

  const handleChange = event => {
    let newValue = event.target.value.trim()

    if (newValue.length > text.length) {
      newValue = formatCardNumber(newValue)
    }

    setText(newValue)
    onChange(event.target.name)(newValue)
  }

  return (
    <StyledInput
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      type={type}
      required={required}
      disableEditing={disableEditing}
      aria-invalid={hasError}
      aria-label={`${label || name}, ${isRequired ? 'Required,' : ''}`}
      hasError={hasError}
      onChange={handleChange}
      onFocus={onFocus}
      {...rest}
    />
  )
}

export const ExpirationDateInput = ({
  id,
  name,
  value = '',
  placeholder,
  label,
  type = 'text',
  required = false,
  isRequired = false,
  hasError = false,
  disableEditing = false,
  onChange = () => {},
  onFocus = () => {},
  ...rest
}: CCInputProps) => {
  const [text, setText] = useState(value || '')

  const handleChange = event => {
    let newValue = event.target.value.trim()

    if (newValue.length > text.length) {
      newValue = formatExpiryDate(newValue)
    }

    setText(newValue)
    onChange(event.target.name)(newValue)
  }

  return (
    <StyledInput
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      type={type}
      required={required}
      disableEditing={disableEditing}
      hasError={hasError}
      aria-invalid={hasError}
      aria-label={`${label || name}, ${isRequired ? 'Required,' : ''}`}
      onChange={handleChange}
      onFocus={onFocus}
      {...rest}
    />
  )
}
