import React, { useEffect, useRef } from 'react'
import Router from 'next/router'

import { NavigationEventsContext } from 'bl-common/src/context/NavigationEventsContext'

const monitor = (obj, prop, handler) => {
  let disabled = false
  const realHandler = obj[prop]
  const monitorHandler = (...args) => {
    if (!disabled) {
      handler(...args)
      return realHandler.apply(obj, args)
    }
  }

  obj[prop] = monitorHandler

  return () => {
    disabled = true
    if (obj[prop] === monitorHandler) {
      obj[prop] = realHandler
    }
  }
}

// NextJS navigation happens like this:
//
// 1. User clicks link
// 2. routeChangeStart event
// 3. async import bundle
// 4. async getInitialProps
// 5. beforeHistoryChange event
// 6. history.pushState
// 7. React#setState
// 8. routeChangeComplete event
//
// When navigating in history:
//
// 1. User clicks back or forward
// 2. history.popState event
// 3. routeChangeStart event
// 4. async getInitialProps
// 5. beforeHistoryChange event
// 6. history.replaceState
// 7. React#setState
// 8. routeChangeComplete event
//
// This component implements a key and a session state storage for each route.
// It does this by stubbing `pushState` and `replaceState` to add `key` to
// saved history entries and sharing it in a context before Next calls setState.

export const NavigationEventsProvider = ({ children }) => {
  const handlersRef = useRef([])
  const onChange = handler => {
    handlersRef.current.push(handler)
    return () => {
      handlersRef.current = handlersRef.current.filter(h => h !== handler)
    }
  }
  useEffect(() => {
    let transition = null
    const notify = event => {
      handlersRef.current.forEach(handler => handler(event, transition))
    }

    const onPushState = () => {
      if (!transition) {
        return
      }
      transition.method = transition.method || 'pushState'
      notify('pushState')
    }
    const onReplaceState = () => {
      if (!transition) {
        return
      }
      transition.method = transition.method || 'replaceState'
      notify('replaceState')
    }
    const onPopState = () => {
      transition = transition || {}
      transition.method = transition.method || 'popState'
      notify('popState')
    }
    const onGetRouteInfo = (route, pathname, query, as, resolvedAs) => {
      transition = Object.assign(transition || {}, {
        route,
        pathname,
        query,
        as,
        resolvedAs,
        shallow: route?.routeProps?.shallow,
      })
      notify('routeChangeStart')
    }
    const onRouteChangeComplete = () => {
      if (transition) {
        notify('routeChangeComplete')
        transition = null
      }
    }

    window.addEventListener('popstate', onPopState)
    Router.events.on('routeChangeComplete', onRouteChangeComplete)
    const unmockGetRouteInfo = monitor(
      Router.router,
      'getRouteInfo',
      onGetRouteInfo
    )
    const unmockPushState = monitor(history, 'pushState', onPushState)
    const unmockReplaceState = monitor(history, 'replaceState', onReplaceState)
    return () => {
      window.removeEventListener('popstate', onPopState)
      Router.events.off('routeChangeComplete', onRouteChangeComplete)
      unmockGetRouteInfo()
      unmockPushState()
      unmockReplaceState()
    }
  }, [])

  return (
    <NavigationEventsContext.Provider value={onChange}>
      {children}
    </NavigationEventsContext.Provider>
  )
}
