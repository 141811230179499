import styled from 'styled-components'

type CssThemeVariablesProps = {
  linkColor?: string
  listMarkerColor?: string
}

export const CssThemeVariables = styled.div<CssThemeVariablesProps>`
  ${({ linkColor }) => linkColor && `--global-color-link: ${linkColor};`}
  ${({ listMarkerColor }) =>
    listMarkerColor && `--global-color-listMarker: ${listMarkerColor};`}
`
