import React from 'react'
import * as Sentry from '@sentry/nextjs'

import { I404Fields } from 'bl-common/src/generated/contentful'

import { ErrorPage as ErrorPage_ } from '../components/ErrorPage'
import { getEntriesWrapper } from '../services/contentfulClient'

type ErrorPageProps = {
  page?: {
    fields: I404Fields
  }
}

const getErrorPage = (statusCode, locale) =>
  getEntriesWrapper({
    content_type: '404',
    'fields.code': statusCode,
    locale,
    include: 4,
  }).then(entries => entries.items[0])

const ErrorPage = ({ page }: ErrorPageProps) => <ErrorPage_ page={page} />

ErrorPage.getInitialProps = async ctx => {
  const { query, res, err } = ctx
  await Sentry.captureUnderscoreErrorException(ctx)
  const statusCode =
    res && res.statusCode
      ? res.statusCode
      : err && err.statusCode
      ? err.statusCode
      : 404

  let errorPage = await getErrorPage(statusCode, query.locale)
  if (!errorPage) {
    errorPage = await getErrorPage(404, query.locale)
  }
  return {
    page: errorPage,
  }
}

export default ErrorPage
