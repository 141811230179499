import React from 'react'

export const BagIcon = props => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3158 5.19658C12.3158 4.10256 11.4263 3.21368 10.3316 3.21368H9.51053C9.16842 1.64103 8.41579 0 6.5 0C5.88421 0 4.17368 0.273504 3.55789 3.21368H2.66842C1.57368 3.21368 0.684211 4.10256 0.684211 5.19658L0 14.0171C0 15.1111 0.889474 16 1.98421 16H11.0158C12.1105 16 13 15.1111 13 13.9487L12.3158 5.19658ZM6.5 1.36752C6.84211 1.36752 7.66316 1.36752 8.07368 3.21368H4.92632C5.26842 2.05128 5.81579 1.36752 6.5 1.36752ZM11.0158 14.6325H1.98421C1.64211 14.6325 1.36842 14.359 1.36842 14.0171L2.05263 5.26496V5.19658C2.05263 4.8547 2.32632 4.5812 2.66842 4.5812H3.35263C3.35263 4.99145 3.28421 5.40171 3.28421 5.81197C3.28421 6.22222 3.55789 6.49573 3.96842 6.49573C4.37895 6.49573 4.65263 6.22222 4.65263 5.81197C4.65263 5.33333 4.65263 4.92308 4.72105 4.5812H8.27895C8.27895 4.92308 8.34737 5.33333 8.34737 5.81197C8.34737 6.22222 8.62105 6.49573 9.03158 6.49573C9.44211 6.49573 9.71579 6.22222 9.71579 5.81197C9.71579 5.53846 9.71579 5.05983 9.71579 4.5812H10.4C10.7421 4.5812 11.0158 4.8547 11.0158 5.26496L11.7 13.9487C11.6316 14.359 11.3579 14.6325 11.0158 14.6325Z"
      fill="currentColor"
    />
  </svg>
)
