import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import throttle from 'lodash/throttle'

import {
  getMyBookingLink,
  myBookingUrl,
} from 'bl-utils/src/routing/getMyBookingLink'

import { withRouter } from '../../context/withRouter'
import { MobileMenuFab } from '../../elements/Fab/MobileMenuFab'
import { BagIcon } from '../../elements/Icons/BagIcon'
import { CloseIcon } from '../../elements/Icons/CloseIcon'
import { PersonIcon } from '../../elements/Icons/PersonIcon'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { INavigationItemFields } from '../../generated/contentful'
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect'
import { Drawer } from '../../units/Drawer'
import { LanguageSelector } from '../LanguageSelector'
import DrawerNav from './DrawerNav'
import { PortalNav } from './PortalNav'
import * as styles from './styles'

function getIcon(icon) {
  switch (icon) {
    case 'Person':
      return PersonIcon
    case 'Bag':
      return BagIcon
    default:
      return null
  }
}

const ItemWithIcon = ({ to, icon, children, ...rest }) => (
  <styles.ListItemWithIcon>
    <styles.NavItem to={to} withIcon {...rest}>
      {icon}
      <Type preset="textSmall" weight="bold">
        {children}
      </Type>
    </styles.NavItem>
  </styles.ListItemWithIcon>
)

const Item = React.forwardRef(
  ({ to, children, ...rest }: any, ref: RefObject<HTMLSpanElement>) => (
    <styles.ListItem>
      <styles.NavItem to={to} {...rest}>
        <Type preset="text" weight="medium">
          <span ref={ref}>{children}</span>
        </Type>
      </styles.NavItem>
    </styles.ListItem>
  )
)
Item.displayName = 'Item'

const whiteNavPreset = {
  transparentWithBlueBorder: 'transparentWithBlueBorderOnDark',
}

const Explore = ({
  to,
  children,
  subNavUrl,
  isWhiteColored,
  preset = 'blue',
  ...rest
}) => (
  <styles._Button
    to={to}
    weight="bold"
    preset={isWhiteColored ? whiteNavPreset[preset] || preset : preset}
    {...rest}
  >
    {children}
  </styles._Button>
)

type NavData = {
  url: string
  pages: any[]
  content: {
    title: string
    description: string
  }
  promoBox?: INavigationItemFields['promoBox']
}

const NavigationBase = ({
  router,
  isAbsolutelyPositioned = false,
  isWhiteColored,
  navigationData = [],
  homeUrl,
  openMobileNav,
  hasSubnavigation,
  bannerHeight,
  hideNavigation = false,
}) => {
  const ref = useRef<HTMLElement>()
  const scroll = useRef(0)
  const scrollUp = React.useRef(0)
  const position = useRef<string | undefined>(undefined)
  const offset = useRef<number | undefined>(undefined)
  const initialNavPos = useRef(0)

  const [subNavOpen, setSubNavOpen] = useState(false)
  const [langSelectorOpen, setLangSelectorOpen] = useState(false)
  const [subNav, setSubNav] = useState<NavData | undefined>()
  const [drawerNav, setDrawerNav] = useState<NavData | undefined>()
  const [fixedMenu, setFixedMenu] = useState(false)
  const [slimMenu, setSlimMenu] = useState(false)
  const [spacerHeight, setSpacerHeight] = useState(0)
  const [hideNav, setHideNav] = useState(false)

  const enableFixedMenu = !hasSubnavigation

  function setRef(el) {
    ref.current = el
  }

  useIsomorphicLayoutEffect(() => {
    initialNavPos.current = ref.current.offsetTop + bannerHeight
    setSpacerHeight(ref.current?.offsetHeight)
  }, [bannerHeight])

  const handleResize = useCallback(() => {
    if (ref?.current) {
      initialNavPos.current = ref.current.offsetTop + bannerHeight
      setSpacerHeight(ref.current.offsetHeight)
    }
  }, [ref])

  useEffect(() => {
    window.addEventListener(
      'resize',
      throttle(() => handleResize(), 100)
    )
    return () => window.removeEventListener('resize', handleResize)
  }, [ref, bannerHeight, slimMenu])

  const handleScroll = useCallback(() => {
    if (!ref?.current || document.body.style.position === 'fixed') {
      return
    }
    const navHeight =
      ref.current.offsetHeight + ref.current.offsetTop + bannerHeight
    const scrollDirection = window.scrollY > scroll.current ? 'down' : 'up'
    scroll.current = window.scrollY

    if (scrollDirection === 'up' && !scrollUp.current) {
      scrollUp.current = window.scrollY
    }

    if (scroll.current > navHeight && scrollDirection === 'up') {
      setFixedMenu(true)
      setSlimMenu(true)
    }

    if (scroll.current > navHeight && scrollDirection === 'down') {
      scrollUp.current = null
      setHideNav(true)
    }

    if (scroll.current <= bannerHeight && scrollDirection === 'up') {
      setFixedMenu(false)
    }

    if (scroll.current > navHeight && scrollDirection === 'up') {
      scrollUp.current = null
      setHideNav(false)
    }
  }, [ref, bannerHeight, slimMenu, fixedMenu, hideNav])

  useEffect(() => {
    function handle() {
      requestAnimationFrame(handleScroll)
    }

    if (enableFixedMenu) {
      window.addEventListener('scroll', handle, { passive: true })
    }

    return () => window.removeEventListener('scroll', handle)
  }, [handleScroll])

  useEffect(() => {
    position.current = document.body.style.position
    return () => {
      document.body.style.position = position.current
      document.body.style.top = ''
      document.body.style.right = ''
      document.body.style.left = ''
    }
  }, [])

  const loadSubNav = (nav, data) => {
    if (subNav) {
      if (subNav.url === nav) {
        setSubNavOpen(false)
        setTimeout(() => {
          document.body.style.position = position.current
          document.body.style.top = ''
          document.body.style.right = ''
          document.body.style.left = ''
          document.documentElement.scrollTop = offset.current
          setSubNav(undefined)
        }, 100)
        return
      }
      setSubNavOpen(true)
    }

    setSubNav({
      url: nav,
      ...data,
    })

    if (document.body.style.position !== 'fixed') {
      const scrollPosition = document.documentElement.scrollTop
      offset.current = scrollPosition
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.right = '0px'
      document.body.style.left = '0px'
    }
  }

  const closeDrawerNav = () => {
    document.body.style.position = position.current
    document.body.style.top = ''
    document.body.style.right = ''
    document.body.style.left = ''
    document.documentElement.scrollTop = offset.current
    setDrawerNav(undefined)
  }

  const loadDrawerNav = (nav, data) => {
    if (subNav) {
      setSubNav(undefined)
    }
    if (subNavOpen) {
      setSubNavOpen(false)
    }
    if (drawerNav) {
      closeDrawerNav()
      return
    }

    setDrawerNav({
      url: nav,
      ...data,
    })

    if (document.body.style.position !== 'fixed') {
      const scrollPosition = document.documentElement.scrollTop
      offset.current = scrollPosition
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.right = '0px'
      document.body.style.left = '0px'
    }
  }

  function closeMenu() {
    if (document.body.style.position === 'fixed') {
      document.body.style.position = position.current
      document.body.style.top = ''
      document.body.style.right = ''
      document.body.style.left = ''
      document.documentElement.scrollTop = offset.current
    }
    setSubNav(null)
  }

  return (
    <>
      <styles.StaticNavSpacer
        show={fixedMenu && !isAbsolutelyPositioned}
        height={spacerHeight}
      />
      <styles.NavCurtain
        initial="collapsed"
        animate={subNav ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { height: '100vh' },
          collapsed: { height: 0 },
        }}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      />
      <styles.Nav
        ref={setRef}
        isWhiteColored={isWhiteColored}
        hideNav={hideNav}
        subNavOpen={!!subNav}
        isAbsolutelyPositioned={isAbsolutelyPositioned}
        isFixed={fixedMenu}
        isSlim={fixedMenu && slimMenu}
        langSelectorOpen={langSelectorOpen}
        data-navigation
        style={{ display: hideNavigation ? 'none' : 'block' }}
      >
        <styles.NavInner>
          <Link aria-label="Home" to={homeUrl || 'Home'} onClick={closeMenu}>
            <styles._Logo isSmall={fixedMenu && !subNav} />
          </Link>
          <styles.LanguageSelectorWrapper>
            <LanguageSelector
              align="right"
              isOpen={langSelectorOpen}
              toggleOpen={() => setLangSelectorOpen(!langSelectorOpen)}
            />
          </styles.LanguageSelectorWrapper>
          <MobileMenuFab
            onClick={openMobileNav}
            transparent={isAbsolutelyPositioned}
            color={isWhiteColored ? 'white' : undefined}
          />
          <styles.List>
            {navigationData.map(item => {
              const { hasIcon, icon, isButton, isDrawer, link } = item.fields
              let Component: any = Item
              let Icon = null
              if (hasIcon) Component = ItemWithIcon
              if (isButton) Component = Explore
              if (hasIcon && icon) {
                Icon = getIcon(icon)
              }
              const to = isButton ? link.fields.link : link.fields.url
              const myBooking = to.includes(myBookingUrl)
                ? getMyBookingLink()
                : null
              const text = isButton ? link.fields.text : link.fields.label
              const subData: Partial<NavData> = item.fields.subLinks &&
                item.fields.subLinks.length && {
                  pages: item.fields.subLinks.map(page => page.fields),
                  content: {
                    title: item.fields.subTitle,
                    description: item.fields.subDescription,
                  },
                  promoBox: item.fields.promoBox,
                }
              return (
                <Component
                  key={to}
                  to={myBooking || to}
                  subNavUrl={subNav?.url}
                  data-cy={text}
                  onClick={e => {
                    if (isDrawer) {
                      e.preventDefault()
                      loadDrawerNav(to, subData)
                      return
                    }

                    if (!subData) {
                      return
                    }

                    e.preventDefault()
                    loadSubNav(to, subData)
                  }}
                  icon={
                    hasIcon ? (
                      <Icon
                        height="1.2em"
                        width={null}
                        style={{ transform: 'translateY(2px)' }}
                      />
                    ) : null
                  }
                  isWhiteColored={isButton ? isWhiteColored : null}
                  preset={isButton ? link.fields.color : null}
                >
                  {text}
                </Component>
              )
            })}
          </styles.List>
        </styles.NavInner>
        <styles.NavPortal isActive={!!subNav}>
          <AnimatePresence initial={false}>
            {subNav ? (
              <motion.div
                key={subNav.url}
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={
                  subNavOpen
                    ? {
                        open: { opacity: 1 },
                        collapsed: { opacity: 0 },
                      }
                    : {
                        open: { opacity: 1, height: 'auto' },
                        collapsed: { opacity: 0, height: 0 },
                      }
                }
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                style={{ gridRow: 1, gridColumn: 1 }}
              >
                <PortalNav
                  pages={subNav.pages}
                  content={subNav.content}
                  promoBox={subNav.promoBox}
                  closeMenu={closeMenu}
                  currentPath={router.asPath}
                  isSubmenu
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </styles.NavPortal>
      </styles.Nav>
      <Drawer
        show={!!drawerNav}
        onDismiss={closeDrawerNav}
        closeButton={
          <styles.CloseButton onClick={closeDrawerNav}>
            <CloseIcon width={12} height={12} />
          </styles.CloseButton>
        }
        noPadding
      >
        {drawerNav && (
          <DrawerNav pages={drawerNav.pages} content={drawerNav.content} />
        )}
      </Drawer>
    </>
  )
}

export const Navigation = withRouter(NavigationBase)
