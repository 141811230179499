import React from 'react'
import NextLink from 'next/link'
import { withRouter } from 'next/router'

import { RouterContext } from 'bl-common/src/context/RouterContext'

const Link: any = props => {
  const { to: href, ...rest } = props

  return <NextLink href={href} {...rest} />
}

const RouterProviderComponent = ({ children, router }) => (
  <RouterContext.Provider value={{ router, Link }}>
    {children}
  </RouterContext.Provider>
)

export const RouterProvider = withRouter(RouterProviderComponent)
