import get from 'lodash/get'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { pb, pt } from 'styled-components-spacing'

import { colors } from '../../constants/colors'
import { base } from '../../constants/sizes'
import { mediaMax } from '../../utils/media'
import { mixins } from '../../utils/mixins'

function toCamelize(str) {
  if (!str) {
    return
  }
  const test = /(?:^\w|[A-Z]|\b\w)/g
  if (!test) {
    return
  }
  return str
    .replace(test, function (letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    })
    .replace(/\s+/g, '')
}

interface SectionProps {
  gradient: any
  gradientStartsWhite?: any
  gradientTrigger?: any
  gradientOffset?: any
  isHeader: any
  textColor: any
  top: { xs: number | undefined }
  bottom: { xs: number | undefined }
  minheight?: any
  background?: { degrees: any; color1: any; color2: any }
  noHorizontalPadding: boolean | number | undefined
  hideOverflowX: boolean | undefined
}

export const StyledSection = styled.section.attrs<SectionProps>(
  props =>
    ({
      'data-gradient':
        props.gradient &&
        toCamelize(get(props.gradient, 'fields.colorName', 'white')),
      'data-gradient-starts-white': props.gradient && props.gradientStartsWhite,
      'data-gradient-trigger':
        props.gradient && (props.gradientTrigger || (props.isHeader && '1')),
      'data-gradient-offset': props.gradient && props.gradientOffset,
    } as unknown) /* Temporary fix for typescript https://github.com/DefinitelyTyped/DefinitelyTyped/issues/51718 */
)<SectionProps>`
  position: relative;
  color: ${({ textColor }) =>
    String(textColor).startsWith('#')
      ? textColor
      : textColor === 'light'
      ? colors.white
      : 'inherit'};

  ${({ top, theme }) => pt(top, theme)};

  ${({ top }) =>
    top &&
    top.xs &&
    mediaMax.md(
      css`
        padding-top: ${top.xs * base}px;
      `
    )};

  ${({ bottom, theme }) => pb(bottom, theme)};

  ${({ bottom }) =>
    bottom &&
    bottom.xs &&
    mediaMax.md(
      css`
        padding-bottom: ${bottom.xs * base}px;
      `
    )};

  ${props =>
    props.minheight && map(props.minheight, val => `min-height: ${val};`)};

  ${({ background }) =>
    background &&
    css`
      background: linear-gradient(
        ${get(background, 'fields.degrees', background.degrees)},
        ${get(background, 'fields.color1', background.color1)},
        ${get(background, 'fields.color2', background.color2)}
      );
    `}

  [data-gradient-container]  & {
    :not([data-gradient='white']) {
      background: none;
    }
  }

  ${props =>
    (!props.textColor || props.textColor[0] !== '#') &&
    css`
      .gradient-dark & {
        color: ${colors.white};
      }
    `};

  ${({ noHorizontalPadding }) => {
    if (!noHorizontalPadding) {
      return mixins.siteGutter()
    }
  }}

  ${({ hideOverflowX }) =>
    hideOverflowX &&
    css`
      overflow-x: hidden;
    `}
`
