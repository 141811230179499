import 'focus-visible/dist/focus-visible.js'

import { createGlobalStyle, css } from 'styled-components'

import { colors } from '../constants/colors'
import { media } from '../utils/media'

export const BaseStyles = createGlobalStyle`
    :root {
      --reach-dialog: 1;
      --color-scrollbar-thumb: ${colors.deepBlue};
      --color-scrollbar-track: ${colors.lightGrey};

      --line-height: 1.5;
      --line-height-heading: 1.15;
    }

    * {
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      -webkit-tap-highlight-color: transparent;
      box-sizing: border-box;
    }

    *::before, *::after {
      box-sizing: inherit;
    }

    *::selection {
      background: ${colors.fountainBlue};
      color: ${colors.cream};
    }


    html {
      box-sizing: border-box;
      color: ${colors.midGrey};
      font-family: 'DINPro', sans-serif;
      font-size: 16px;
      line-height: var(--line-height);
      position: relative;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;

      @media (max-width: 767px) and (min-resolution: 192dpi) {
        &:not(.scroll-lock) { overflow-x: hidden; }
      }

      @supports (scrollbar-color: auto) {
        scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track);
      }
    }

    h1, h2, h3, h4, h5, h6 {
      line-height: var(--line-height-heading);
    }

    body {
      box-sizing: border-box;
      max-width: 100vw;
      min-height: 100vh;
    }

    body.t-dark {
      background: ${colors.darkmode};
    }

    /*
      This will hide the focus indicator if the element receives focus via the mouse,
      but it will still show up on keyboard focus.
    */
    .js-focus-visible :focus:not(.focus-visible) {
      outline: none;
    }

    .fb_dialog {
      display: none !important;
    }

    .show_fb_dialog .fb_dialog {
      display: inline !important;
    }

    input[type],
    [contenteditable] {
      user-select: text;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
    }

    base, basefont, datalist, head, meta, script, style, title,
    noembed, param, template {
      display: none;
    }

    a {
      color: inherit;
      text-decoration: none;
      touch-action: manipulation;
    }

    b {
      font-weight: 400;
    }

    pre {
      font-family: inherit;
    }


    em,
    i {
      font-style: normal;
    }

    ul {
      margin: 0;
    }

    ol, ul, li {
      list-style: none;
      padding: 0;
    }

    button {
      appearance: none;
      background-color: transparent;
      border: 0;
      padding: 0;
      touch-action: manipulation;
      user-select: none;
      font-family: inherit;
    }

    input,
    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
      min-width: 0;
      font-size: inherit;
      font-family: inherit;
    }

    input::-ms-clear {
      display: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      appearance: none;
    }

    svg {
      display: block;
    }

    img {
      max-width: 100%;
      display: block;
    }

    select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    br {
      display: none;

      ${media.lg(css`
        display: block;
      `)}
    }

    .js .jsBG {
      background-image: none !important;
    }

    .no-js .Appear {
      opacity: initial !important;
      transform: none !important;
    }
  `
