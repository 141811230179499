import React from 'react'
import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { PartialBookingEngine } from '../../styles/types'

type StyledInputProps = {
  hasError?: boolean
  disableEditing?: boolean
  disableBorderRadiusRightSide?: boolean
  themeStyle?: PartialBookingEngine['inputTextField']['input']
}

export const StyledInput = styled.input<StyledInputProps>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  border: 1px solid
    ${props => props?.themeStyle?.borderColor ?? colors.formGrey};
  color: ${props => props?.themeStyle?.textColor ?? colors.formDark};
  font-size: 16px;
  height: 56px;
  outline: none;
  padding: ${({ theme }) => `${theme.spacing[0.5]} ${theme.spacing[1]}`};
  width: 100%;
  -webkit-appearance: none;
  border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;
  border-top-right-radius: ${props =>
    props.disableBorderRadiusRightSide ? 0 : props?.themeStyle?.borderRadius}px;
  border-bottom-right-radius: ${props =>
    props.disableBorderRadiusRightSide ? 0 : props?.themeStyle?.borderRadius}px;
  transition: box-shadow 150ms, border 150ms;

  ::placeholder {
    opacity: 0.5;
    color: ${props => props?.themeStyle?.textColor ?? 'auto'};
  }

  :hover {
    border: 1px solid
      ${props => props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
  }

  :focus {
    border: 1px solid
      ${props => props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
    background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  }

  ${({ hasError, themeStyle }) =>
    hasError &&
    css`
      background: ${rgba(colors.errorRed, 0.15)};
      border: 1px solid ${colors.errorRed};

      &:hover,
      &:focus {
        box-shadow: 0 0 12px
          ${rgba(themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
      }
    `}

  ${({ disableEditing }) =>
    disableEditing &&
    css`
      color: transparent;
      text-shadow: 0 0 0 ${colors.formDark};
      cursor: pointer;
      caret-color: transparent;
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      color: transparent;
      text-shadow: 0 0 0 ${colors.formDark};
      background: ${colors.lightGrey} !important;
    `}
`

const noop = () => {}

export const Input = ({
  id = '',
  name = '',
  label = '',
  placeholder = '',
  type = 'text',
  value = type === 'file' ? undefined : '',
  required = false,
  isRequired = false,
  hasError = false,
  disableEditing = false,
  onChange = undefined,
  onFocus = undefined,
  onBlur = undefined,
  themeStyle = undefined,
  disableBorderRadiusRightSide = false,
  ...rest
}) => (
  <StyledInput
    id={id}
    name={name}
    value={value}
    placeholder={placeholder}
    type={type}
    required={required}
    disableEditing={disableEditing}
    hasError={hasError}
    onChange={onChange || noop}
    aria-invalid={hasError}
    aria-label={`${label || name}, ${isRequired ? 'Required,' : ''}`}
    onFocus={onFocus || noop}
    readOnly={disableEditing}
    onBlur={onBlur}
    themeStyle={themeStyle}
    disableBorderRadiusRightSide={disableBorderRadiusRightSide}
    {...rest}
  />
)

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number']),
  placeholder: PropTypes.string,
  disableEditing: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}
