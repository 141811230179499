import React from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { breakpoints } from '../../constants/breakpoints'
import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Button } from '../../elements/Button/Button'
import { Link, NavLink } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { theme } from '../../styles/theme'
import { between } from '../../utils/between'
import { media, mediaMax, mediaObj } from '../../utils/media'
import { Logo } from './Logo'

type LogoProps = {
  isSmall?: boolean
}

type NavProps = {
  isFixed?: boolean
  isAbsolutelyPositioned?: boolean
  hideNav?: boolean
  subNavOpen?: boolean
  langSelectorOpen?: boolean
  spacerHeight?: any
  isSlim?: boolean
  isWhiteColored?: boolean
}

type ContentWrapProps = {
  spaced?: boolean
}

type ImageWrapperProps = {
  transitionSpeed?: any
}

type DescriptionProps = {
  showOnMobile?: boolean
}

type ArrowProps = {
  transitionSpeed?: any
  isSmall?: boolean
}

type NavPortalProps = {
  isActive?: boolean
}

type PortalWrapperProps = {
  spacingLeft?: any
  index?: number
  withShadow?: boolean
  isFlat?: boolean
  transitionSpeed?: any
}

export const List = styled.ul`
  align-items: center;
  margin-left: auto;
  display: none;
  position: relative;
  ${media.mlg(css`
    display: flex;
  `)};
`

export const _Logo = styled(Logo)<LogoProps>`
  width: 93px;
  overflow: visible;
  ${media.mlg(css`
    width: ${({ theme, isSmall }: LogoProps & { theme: any }) =>
      theme.spacing[isSmall ? 6 : 8]};
    margin-top: 0;
    transition: width ${durations.short}ms ease-in-out;
  `)};
  .t-dark & {
    color: white;
  }
`

export const _Button = styled(Button)`
  padding: ${between(12 / modularScale, 12)} ${between(40 / modularScale, 40)};
  margin: ${between(6 / modularScale, 6)} 0;
  ${mediaMax.md(css`
    padding: 12px 40px;
    margin: 6px 0;
  `)};
  &:first-of-type {
    margin-left: ${({ theme }) => theme.spacing[2.5]};
    @media (min-width: ${breakpoints.md}px) {
      margin-left: ${({ theme }) => theme.spacing[3.5]};
    }
    @media (min-width: 1200px) {
      margin-left: ${({ theme }) => theme.spacing[4]};
    }
    @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
      margin-left: 50px;
    }
  }
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing[1]};
    @media (min-width: ${breakpoints.md}px) {
      margin-left: ${({ theme }) => theme.spacing[1.5]};
    }
    @media (min-width: 1200px) {
      margin-left: ${({ theme }) => theme.spacing[1.5]};
    }
    @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
      margin-left: 24px;
    }
  }
`

export const LanguageSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: ${({ theme }) => theme.spacing[2.5]};
  order: 1;

  ${media.md(css`
    svg {
      width: 25px;
      height: 25px;
    }
  `)};

  ${mediaMax.mlg(css`
    margin-left: auto;
    margin-right: ${({ theme }) => theme.spacing[1]};
    order: 0;
  `)};

  .t-dark & {
    svg {
      stroke: ${colors.white};
    }
  }
`

export const ListItem = styled.li`
  &:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.spacing[2]};
    @media (min-width: ${breakpoints.md}px) {
      margin-left: ${({ theme }) => theme.spacing[1.5]};
    }
    @media (min-width: 1200px) {
      margin-left: ${({ theme }) => theme.spacing[2.5]};
    }
    @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
      margin-left: 22px;
    }
  }
`

export const ListItemWithIcon = styled.li`
  margin-left: ${({ theme }) => theme.spacing[3]};
  @media (min-width: ${breakpoints.md}px) {
    margin-left: ${({ theme }) => theme.spacing[5]};
  }
  @media (min-width: 1200px) {
    margin-left: ${({ theme }) => theme.spacing[5]};
  }
  @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
    margin-left: 22px;
  }
  & ~ & {
    margin-left: ${({ theme }) => theme.spacing[2]};
    @media (min-width: ${breakpoints.md}px) {
      margin-left: ${({ theme }) => theme.spacing[1.5]};
    }
    @media (min-width: 1200px) {
      margin-left: ${({ theme }) => theme.spacing[2.5]};
    }
    @media (min-width: ${breakpoints.md}px) and (orientation: portrait) {
      margin-left: 22px;
    }
  }
`

export const StaticNavSpacer = styled.div<{ show: boolean; height: number }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  height: ${({ height }) => height}px;
  background: transparent;
  transition: height 0.3s ease-in-out;
`

export const NavItem = styled(({ withIcon, isWhiteColored, ...rest }) => (
  <NavLink {...rest} />
))`
  color: ${colors.midGrey};
  display: block;
  position: relative;
  transition: opacity ${durations.medium}ms, color ${durations.short}ms linear;
  ${({ withIcon }) =>
    withIcon &&
    css`
      color: ${colors.deepBlue};
      display: flex;
      align-items: baseline;
      svg {
        margin-right: 10px;
        transition: opacity ${durations.medium}ms,
          color ${durations.short}ms linear;
      }
    `}
  &:hover {
    color: ${colors.deepBlue};
    & > * {
      color: ${colors.deepBlue};
    }
  }
  &[data-active='true']::before {
    width: 16px;
  }
  &[data-active='true'] {
    color: ${colors.deepBlue};
    & > * {
      color: ${colors.deepBlue};
      font-weight: 500;
    }
  }
  .t-dark &,
  .t-dark &:hover,
  .t-dark &:hover > *,
  .t-dark &[data-active='true'],
  .t-dark &[data-active='true'] > * {
    color: white;
  }
`

export const NavInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: padding 0.3s ease-in-out;
  ${media.mlg(css`
    padding-bottom: ${({ theme }) => theme.spacing[1.5]};
  `)}
`

export const NavCurtain = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: ${zIndex.aboveMenu};
  .t-dark & {
    background-color: ${colors.darkmode};
  }
`

export const Nav = styled.nav<NavProps>`
  z-index: ${zIndex.aboveMenu};
  width: 100%;
  max-height: 100vh;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[2.5]} 0`};
  padding: ${({ theme, isSlim }) =>
    `${theme.spacing[2]} ${theme.spacing[2.5]} ${
      isSlim ? theme.spacing[2] : theme.spacing[1.5]
    }`};
  color: ${colors.dark};
  position: ${({ isFixed, isAbsolutelyPositioned }) =>
    isFixed ? 'fixed' : isAbsolutelyPositioned ? 'absolute' : 'static'};
  top: 0;
  right: 0;
  left: 0;
  will-change: transform;
  transform: ${({ hideNav }) =>
    hideNav ? 'translate3d(0, -100%, 0)' : 'translate3d(0, 0, 0)'};
  overflow-y: ${({ subNavOpen, langSelectorOpen }) =>
    subNavOpen ? 'auto' : langSelectorOpen ? 'unset' : 'hidden'};
  transition: transform 0.3s ease-in-out, padding 0.3s ease-in-out;
  &::before {
    content: '';
    position: ${({ isFixed, isAbsolutelyPositioned }) =>
      isFixed ? 'fixed' : isAbsolutelyPositioned ? 'absolute' : 'static'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    height: ${({ spacerHeight }) => `${spacerHeight}px`};
    z-index: ${zIndex.behind};
    opacity: ${({ isFixed, isAbsolutelyPositioned }) =>
      isFixed || !isAbsolutelyPositioned ? 1 : 0};
    transition: opacity ${durations.long}ms ease;
    .t-dark & {
      background: ${colors.darkmode};
    }
  }
  @media (min-width: 1180px) {
    ${({ theme, isSlim, subNavOpen }) => css`
      padding: ${isSlim && !subNavOpen ? 0 : theme.spacing[1.5]}
        ${theme.spacing[5]} ${!isSlim && subNavOpen ? theme.spacing[4] : 0};
      ${subNavOpen && 'overflow-y: scroll;'}
      ${NavInner} {
        ${isSlim && !subNavOpen && 'padding-bottom: 0;'}
      }
    `}
  }
  @media (min-width: 1600px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: 2000px) {
    padding-left: 160px;
    padding-right: 160px;
  }
  ${({ isWhiteColored }) =>
    isWhiteColored &&
    css`
      ${NavItem},
      ${NavItem}[data-active='true'] > *,
      ${NavItem}:hover > *,
      ${NavItem}:focus > *,
      ${_Logo} {
        color: ${colors.white};
      }
    `};
`

export const ContentWrap = styled.div<ContentWrapProps>`
  padding: ${({ theme, spaced }) =>
    spaced
      ? `${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[2]}`
      : `${theme.spacing[1]} 0 0`};
  ${media.mlg(css`
    padding: ${({ theme, spaced }: ContentWrapProps & { theme: any }) =>
      spaced
        ? `${theme.spacing[1]} ${theme.spacing[1]} ${theme.spacing[1.5]}`
        : `${theme.spacing[1.5]} 0 0`};
  `)}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

export const ImageOverflow = styled.div<{ $imageRatio: number }>`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ $imageRatio }) => `${$imageRatio * 100}%`};
`

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: ${({ transitionSpeed }) => `transform ${transitionSpeed}ms ease`};
`

export const Heading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing[0.5],
})

export const Description = styled(Type)<DescriptionProps>`
  ${mediaMax.mlg(css`
    ${({ showOnMobile }: DescriptionProps) => !showOnMobile && 'display: none;'}
  `)}
`

export const Arrow = styled.div<ArrowProps>`
  transition: ${({ transitionSpeed }) => `${transitionSpeed}ms ease`};
  transition-property: opacity, transform;
  ${media.mlg(css`
    .t-dark & path {
      fill: ${colors.blueOnDark};
    }
  `)}
`

export const SubLinks = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1],
  marginTop: theme.spacing[1],

  [mediaObj.mlg]: {
    marginTop: theme.spacing[0.5],
  },
})

export const SubLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[0.5],

  [Arrow]: {
    opacity: 0,
    paddingTop: '2px',
  },

  [mediaObj.mlg]: {
    '&:hover': {
      [Arrow]: {
        opacity: 1,
        transform: 'translate3d(20%, 0, 0)',
      },
    },
  },
})

export const Stars = styled.ul({
  display: 'flex',
  padding: `${theme.spacing[0.5]} 0`,
  color: colors.deepBlue,

  [mediaObj.mlg]: {
    padding: `0 0 ${theme.spacing[0.5]} 0`,
  },

  '.t-dark &': {
    color: colors.blueOnDark,
  },
})

export const NavPortal = styled.div<NavPortalProps>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 auto;
  transition: padding-top
    ${({ isActive }) => (isActive ? 0 : durations.short)}ms ease-in-out;
  ${media.md(css`
    padding-top: ${({ theme, isActive }: NavPortalProps & { theme: any }) =>
      isActive ? theme.spacing[2.5] : 0};
  `)}
  .t-dark & {
    color: white;
  }
`

export const PortalWrapper = styled.div<PortalWrapperProps>`
  margin-top: ${({ theme, index }) =>
    index === 0 || index === 2 ? `calc(0px - ${theme.spacing[8]})` : 0};
  margin-bottom: ${({ theme, isFlat }) => (isFlat ? 0 : theme.spacing[3.5])};
  height: 100%;
  ${({ withShadow }) =>
    withShadow &&
    css`
      margin-bottom: ${({
        theme,
        isFlat,
      }: PortalWrapperProps & { theme: any }) =>
        isFlat ? 0 : theme.spacing[2.5]};
    `}
  ${media.md(css`
    margin-top: ${({ theme, index }: PortalWrapperProps & { theme: any }) =>
      index === 2 || index === 3 ? theme.spacing[2] : 0};
  `)};
  ${media.mlg(css`
    margin-left: ${({
      theme,
      spacingLeft,
    }: PortalWrapperProps & { theme: any }) => theme.spacing[spacingLeft]};
    margin-bottom: ${({ theme, isFlat }: PortalWrapperProps & { theme: any }) =>
      isFlat ? 0 : theme.spacing[2]};
  `)};
`

export const PortalContainer = styled(
  ({ transitionSpeed, withShadow, ...rest }) => <Link {...rest} />
)`
  display: block;
  position: relative;
  overflow: hidden;
  z-index: ${zIndex.menu};

  transition: ${({ transitionSpeed }) => `transform ${transitionSpeed}ms ease`};
  ${({ withShadow }) =>
    withShadow &&
    css`
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    `}
  ${media.mlg(css`
    max-width: 480px;
    margin-left: auto;

    .t-dark & {
      background: ${colors.darkmode};
    }
  `)};

  ${Arrow} {
    opacity: 0;
  }

  &:hover,
  &:focus {
    transform: translate3d(0, -8px, 0);
    ${ImageWrapper} {
      transform: scale(1.1) translateZ(0);
    }
    ${Arrow} {
      opacity: 1;
      transform: translate3d(-20%, 0, 0);
    }
  }
`

export const CloseButton = styled.button`
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -13px;
  margin-top: -12px;
  .t-dark & polygon {
    fill: white;
  }
`
