import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'

import { colors } from '../../constants/colors'
import { CurrencyContext } from '../../context/Currency/CurrencyProvider'
import { Type } from '../../elements/Typography/Typography'
import { useBreakpoints } from '../../hooks/useBreakpoints'
import { Appear } from '../../units/Appear'
import { media } from '../../utils/media'
import { mixins } from '../../utils/mixins'
import Card from './Card'

const DrawerTitle = styled.div`
  ${media.md(css`
    padding-top: ${({ theme }) => theme.spacing[2.5]};
    padding-bottom: ${({ theme }) => theme.spacing[1]};
  `)};
  ${media.mlg(css`
    grid-column: span 3;
  `)};
`

const DrawerItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: span 12;
  grid-column-gap: ${({ theme }) => theme.spacing[2.5]};

  ${media.mlg(css`
    display: flex;
    flex-wrap: wrap;
    grid-column: span 9;
    grid-column-gap: 0;
    margin-top: 0;
  `)}
`

const DrawerItem = styled.div`
  flex: 1 1 50%;
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};

  > div {
    flex: 1;
  }

  ${media.mlg(css`
    flex-basis: 25%;
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  `)};
`

const DrawerSection = styled.section`
  position: relative;

  padding-top: ${({ theme }) => theme.spacing[3.5]};
  padding-right: ${({ theme }) => theme.spacing[2.5]};
  padding-left: ${({ theme }) => theme.spacing[2.5]};
  padding-bottom: ${({ theme }) => theme.spacing[2.5]};

  ${media.mlg(css`
    padding-top: ${({ theme }) => theme.spacing[5]};
    padding-right: ${({ theme }) => theme.spacing[5]};
    padding-left: ${({ theme }) => theme.spacing[5]};
    padding-bottom: ${({ theme }) => theme.spacing[4.5]};

    @media (min-aspect-ratio: 3/2) {
      padding-bottom: ${({ theme }) => theme.spacing[2]};
    }

    .t-dark & {
      background: ${colors.darkmodeOffset};
      color: white;
    }
  `)};

  @media (min-width: 1600px) {
    padding-left: 120px;
    padding-right: 120px;
  }
  @media (min-width: 2000px) {
    padding-left: 160px;
    padding-right: 160px;
  }
`

const DrawerInner = styled.div`
  ${media.mlg(css`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: ${mixins.span({ columns: 0, gutters: 1 })};
    margin: 0 auto;
  `)};
`

const TabletAndUp = styled.div`
  display: none;

  ${media.mlg(css`
    display: block;
  `)}
`

const Title = ({ children }) => (
  <Appear>
    <Type
      as="h2"
      weight="bold"
      size={{ xs: 20, md: 30 }}
      bottom={{ xs: 2.5, md: 1 }}
    >
      {children}
    </Type>
  </Appear>
)

const Description = ({ children }) => (
  <TabletAndUp>
    <Appear>
      <Type weight="light" size={{ xs: 18, md: 24 }}>
        {children}
      </Type>
    </Appear>
  </TabletAndUp>
)

const DrawerNav = ({ pages, content }) => {
  const [isShort, setIsShort] = React.useState(window.innerHeight < 800)
  const { exchangeRates } = useContext(CurrencyContext)
  const { mediaMlg } = useBreakpoints()

  const handleResize = () => {
    setIsShort(window.innerHeight < 800)
  }

  const onBookClick = product => {
    const { productReference } = product

    if (productReference && exchangeRates) {
      triggerEvent({
        event: 'select_item',
        ecommerce: {
          item_list_id: 'book_now',
          item_list_name: 'Book now',
          currency: 'EUR',
          items: [
            {
              item_id: productReference.fields.productId,
              item_name: productReference.fields.name,
              currency: 'EUR',
              item_brand: 'Blue Lagoon',
              item_category: productReference.fields.category,
              item_list_id: 'book_now',
              item_list_name: 'Book now',
              price: calcPrice(
                productReference.fields.price.fields.isk,
                exchangeRates?.EUR
              ),
              quantity: 1,
            },
          ],
        },
      })
    }
  }

  React.useEffect(() => {
    const productReferences = pages
      .filter(product => product.productReference)
      .map(product => product.productReference)

    if (exchangeRates) {
      triggerEvent({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: 'book_now',
          item_list_name: 'Book now',
          items: productReferences.map(product => ({
            item_id: product.fields.productId,
            item_name: product.fields.name,
            currency: 'EUR',
            item_brand: 'Blue Lagoon',
            item_category: product.fields.category,
            item_list_id: 'book_now',
            item_list_name: 'Book now',
            price: calcPrice(
              product.fields.price.fields.isk,
              exchangeRates?.EUR
            ),
            quantity: 1,
          })),
        },
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [exchangeRates])

  return (
    <DrawerSection data-cy="book-drawer">
      <DrawerInner>
        {content ? (
          <DrawerTitle>
            <Title>{content.title}</Title>
            <Description>{content.description}</Description>
          </DrawerTitle>
        ) : null}
        <DrawerItems>
          {pages.map(drawer => (
            <DrawerItem key={drawer.title}>
              <Appear>
                <Card
                  title={drawer.title}
                  description={drawer.description}
                  image={drawer.image}
                  path={drawer.secondaryCtaUrl || drawer.primaryCtaUrl}
                  spacingLeft={2}
                  imageRatio={isShort ? 0.83 : !mediaMlg ? 1.11 : 1.16}
                  headingSize={16}
                  withShadow
                  isFlat
                  priceRef={drawer.priceRef}
                  priceFormat={drawer.priceFormat}
                  showPrice
                  arrowSize={
                    !mediaMlg
                      ? { width: 10, height: 8 }
                      : { width: 12, height: 9 }
                  }
                  smallArrow
                  onBookClick={() => onBookClick(drawer)}
                />
              </Appear>
            </DrawerItem>
          ))}
        </DrawerItems>
      </DrawerInner>
    </DrawerSection>
  )
}

export default DrawerNav
