import React, { useCallback, useEffect, useState } from 'react'

import { BreadcrumbContext } from 'bl-common/src/context/BreadcrumbContext'
import { useNavigationEvents } from 'bl-common/src/context/useNavigationEvents'
import { usePageState } from 'bl-common/src/context/usePageState'

const BREADCRUMB_KEY = 'breadcrumb'

export const BreadcrumbManager = ({ children }) => {
  const { getState, setState } = usePageState()
  const [ref] = useState({
    currentBreadcrumb: getState(BREADCRUMB_KEY),
    lastName: null,
    lastLocation: null,
    firstRender: true,
  })

  const savedBreadcrumb = getState(BREADCRUMB_KEY)
  const breadcrumb = ref.firstRender
    ? null
    : savedBreadcrumb || ref.currentBreadcrumb

  if (!savedBreadcrumb && breadcrumb) {
    setState(BREADCRUMB_KEY, breadcrumb)
  }

  useNavigationEvents(event => {
    switch (event) {
      case 'popState':
        ref.currentBreadcrumb = null
        break
      case 'pushState':
        ref.currentBreadcrumb = ref.lastName
          ? {
              name: ref.lastName,
              to: ref.lastLocation,
              delta: -1,
            }
          : ref.currentBreadcrumb
          ? {
              name: ref.currentBreadcrumb.name,
              to: ref.currentBreadcrumb.to,
              delta: ref.currentBreadcrumb.delta - 1,
            }
          : null
        ref.lastName = null
        break
      case 'routeChangeComplete':
        ref.lastLocation = location.pathname
        break
    }
  }, [])

  useEffect(() => {
    // Re-render on client if we have a breadcrumb.
    ref.firstRender = false

    // Store current location.
    ref.lastLocation = location.pathname
  }, [])

  const saveBreadcrumb = useCallback(name => {
    ref.lastName = name
  }, [])

  const context = {
    saveBreadcrumb,
    breadcrumb,
  }

  return (
    <BreadcrumbContext.Provider value={context}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
