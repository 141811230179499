export const zIndex = {
  shadow: -2,
  behind: -1,
  above: 1,
  above2: 2,
  behindMenu: 3,
  menu: 10,
  aboveMenu: 12,
  drawer: 10,
  newLayer: 15,
  modal: 15,
}
