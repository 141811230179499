/* eslint-disable @typescript-eslint/unbound-method */
import React, { Component } from 'react'
import { timingFunctions as timing } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { zIndex as zIndexUtil } from '../../constants/zIndex'
import { media } from '../../utils/media'
import { lockedScrollOffset } from './lockedScrollOffset'

type MobileMenuFabProps = {
  onClick?: any
  transparent?: boolean
  isPressed?: boolean
  color?: string
}

type ButtonProps = {
  transparent?: boolean
  zIndex?: number
}

type AnimationProps = {
  icon?: string
}

const Icon = ({ icon, color }) => (
  <Animation icon={icon}>
    <TopLine color={color} />
    <MiddleLine color={color} />
    <BottomLine color={color} />
  </Animation>
)

const Button = styled.button<ButtonProps>`
  position: relative;
  background: ${({ transparent }) =>
    transparent ? 'transparent' : colors.white};
  cursor: pointer;
  display: flex;
  user-select: none;
  margin-left: 10px;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : zIndexUtil.aboveMenu)};

  &::after {
    content: '';
    position: absolute;
    inset: -10px;
  }

  .t-dark & {
    background: ${({ transparent }) =>
      transparent ? 'transparent' : colors.darkmode};
  }

  ${media.mlg(css`
    display: none;
  `)};

  /* Use props to re-evaluate so we end up with the right scrollbar width. */
  ${() => lockedScrollOffset()}
`

const Line = styled.div<{ color?: string }>`
  background: ${({ color = '#454647' }) => color};
  height: 2px;
  transition: transform ${durations.short}ms ${timing('easeOutCubic')},
    opacity 0.2s ease-out;
  width: 18px;
  will-change: transform;

  .t-dark & {
    background: white;
  }
`

const TopLine = styled(Line)``

const MiddleLine = styled(Line)`
  transition: transform ${durations.micro}ms ${timing('easeOutCubic')};
  transform-origin: left;
`

const BottomLine = styled(Line)``

const Animation = styled.div<AnimationProps>`
  overflow: visible;
  margin: auto;
  transition: transform ${durations.medium}ms ${timing('easeOutCubic')};
  width: 18px;

  ${TopLine} {
    ${props => {
      if (props.icon === 'open') {
        return css`
          transform: rotateZ(0deg) translateX(0) translateY(-4px);
        `
      }
      if (props.icon === 'close') {
        return css`
          transform: rotateZ(45deg) translateX(1px) translateY(2px);
        `
      }

      return null
    }};
  }

  ${BottomLine} {
    ${function (props) {
      if (props.icon === 'open') {
        return css`
          transform: rotateZ(0deg) translateX(0) translateY(4px);
        `
      }
      if (props.icon === 'close') {
        return css`
          transform: rotateZ(-45deg) translateX(1px) translateY(-2px);
        `
      }

      return null
    }};
  }

  ${MiddleLine} {
    ${function (props) {
      if (props.icon === 'open') {
        return css`
          transform: scaleX(0.72);
        `
      }
      if (props.icon === 'close') {
        return css`
          transform: scaleX(0);
        `
      }

      return null
    }};
  }
`

export class MobileMenuFab extends Component<MobileMenuFabProps> {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { onClick } = this.props

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  render() {
    const { isPressed, transparent, color, ...rest } = this.props

    return (
      <Button
        type="button"
        aria-pressed={isPressed}
        aria-label="Toggle Navigation"
        onClick={this.handleClick}
        transparent={transparent}
        data-cy="hamburger"
        {...rest}
      >
        <Icon icon={isPressed ? 'close' : 'open'} color={color} />
      </Button>
    )
  }
}
