import React, { useContext, useEffect } from 'react'

import { CloseFab } from 'bl-common/src/elements/Fab/CloseFab'
import { EscapeStackContext } from 'bl-common/src/units/EscapeStack'

import { BreadcrumbLink } from './BreadcrumbLink'

export const BreadcrumbFab = () => (
  <BreadcrumbLink>
    {({ to, onClick }) => <WithEscape to={to} onClick={onClick} />}
  </BreadcrumbLink>
)

const WithEscape = ({ to, onClick }) => {
  const escapeContext = useContext(EscapeStackContext)

  useEffect(() => {
    escapeContext.push(onClick)

    return () => escapeContext.pop()
  }, [])
  return <CloseFab to={to} onClick={onClick} />
}
