import {
  IPageAboutMain,
  ISiteConfigFields,
} from 'bl-common/src/generated/contentful'

import { getEntriesWrapper } from '../services/contentfulClient'

const SUPPORTED_LANGUAGES = ['en', 'is']
const DEFAULT_LANGUAGE = 'en'

const getLanguageFromPathname = pathname => {
  const [, firstSlug] = pathname.split(/[/?#]/g)

  return SUPPORTED_LANGUAGES.find(key => key === firstSlug) || DEFAULT_LANGUAGE
}

const fetchSiteConfig = async pathname => {
  const language = getLanguageFromPathname(pathname)

  const [siteConfigRes, aboutUsNavigationRes] = await Promise.all([
    getEntriesWrapper<ISiteConfigFields>({
      content_type: 'siteConfig',
      include: 6,
      'fields.language': language,
    }),

    getEntriesWrapper<IPageAboutMain>({
      content_type: 'pageAboutMain',
      include: 3,
      locale: language,
      select:
        'fields.navigationGroups,fields.homeLink,fields.sideDisclaimer,fields.sideLinks',
    }),
  ])

  const siteConfig = siteConfigRes.items?.[0]
  const aboutUsNavigation = aboutUsNavigationRes.items?.[0]?.fields ?? null

  return {
    language,
    navigation: siteConfig.fields.nav,
    footer: siteConfig.fields.footer,
    metadata: siteConfig.fields.metadata,
    alertBanners: siteConfig.fields.alertBanners,
    bannerColor: siteConfig.fields.bannerColor,
    notifications: siteConfig.fields.notifications,
    aboutUsNavigation,
  }
}

export { fetchSiteConfig }
