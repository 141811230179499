import React, { createContext, useState } from 'react'
import dynamic from 'next/dynamic'

const ConfirmActionModal = dynamic(
  () => import('../elements/ConfirmActionModal/ConfirmActionModal'),
  {
    ssr: false,
  }
)

interface Error {
  type: string
  title: string
  message: string
  buttonLabel: string
}

interface ErrorContextState {
  error?: Error
  showError: (error: Error | undefined) => void
  hideError: () => void
}

export const ErrorContext = createContext<ErrorContextState>(null)

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null)

  const hideError = () => {
    setError(null)
  }

  const showError = (e: Error) => {
    setError(e)
  }

  return (
    <ErrorContext.Provider value={{ error, showError, hideError }}>
      <>
        {children}
        <ConfirmActionModal
          show={!!error}
          onHide={hideError}
          title={error?.title}
          message={error?.message}
          buttonLabel={error?.buttonLabel}
          isPopUp
        />
      </>
    </ErrorContext.Provider>
  )
}
