import React from 'react'
import omit from 'lodash/omit'
import { darken, lighten, rgba } from 'polished'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import { mb, ml, mr, mt } from 'styled-components-spacing'

import { colors } from '../../constants/colors'
import { modularScale } from '../../constants/sizes'
import { between } from '../../utils/between'
import { media, mediaMax } from '../../utils/media'
import { Type } from '../Typography/Typography'

export const buttonPadding = {
  small: () => css`
    padding: ${between(16 / modularScale, 16)} ${between(
    20 / modularScale,
    20
  )};
    ${mediaMax.md(css`
      padding: 13px 20px 14px;
    `)};
  `,
  medium: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(
    24 / modularScale,
    24
  )};
    ${mediaMax.md(css`
      padding: 13px 24px 14px;
    `)};
  `,
  large: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(
    54 / modularScale,
    54
  )};
    ${mediaMax.md(css`
      padding: 13px 54px 14px;
    `)};
  `,
  menu: () => css`
    padding: ${between(18 / modularScale, 18)} ${between(
    40 / modularScale,
    40
  )};
    ${mediaMax.md(css`
      padding: 13px 40px 14px;
    `)};
  `,
}

export const buttonPresets = {
  blue: {
    from: '#6793AE',
    to: '#50A7BA',
    hover: '#3A7E8D',
    degrees: '90deg',
    color: colors.white,
  },
  grey: {
    from: rgba('#C4C4C4', 0.95),
    to: rgba('#C4C4C4', 0.95),
    hover: lighten(0.05, '#C4C4C4'),
    degrees: '90deg',
  },
  red: {
    from: rgba('#9D3628', 0.95),
    to: rgba('#78434C', 0.95),
    hover: lighten(0.05, '#78434C'),
    degrees: '90deg',
    color: colors.white,
  },
  lightGrey: {
    from: rgba('#F2F2F2', 0.95),
    to: rgba('#F2F2F2', 0.95),
    hover: lighten(0.05, '#F2F2F2'),
    degrees: '90deg',
    color: 'inherit',
  },
  white: {
    from: rgba('#FFFFFF', 0.95),
    to: rgba('#FFFFFF', 0.95),
    hover: lighten(0.05, '#FFFFFF'),
    degrees: '90deg',
    color: 'inherit',
  },
  whiteWithBlue: {
    from: rgba('#FFFFFF', 0.95),
    to: rgba('#FFFFFF', 0.95),
    hover: lighten(0.05, '#FFFFFF'),
    degrees: '90deg',
    color: colors.deepBlue,
  },
  dark: {
    from: rgba('#50555B', 0.95),
    to: rgba('#50555B', 0.97),
    hover: 'rgba(100, 100, 100, 0.95)',
    degrees: '-125deg',
  },
  darker: {
    from: rgba('#3c4044', 0.95),
    to: rgba('#383b40', 0.95),
    hover: 'rgba(100, 100, 100, 0.6)',
    degrees: '-125deg',
  },
  black: {
    from: '#7C8083',
    to: '#282A2C',
    hover: 'rgba(100, 100, 100, 0.95)',
    color: colors.white,
    degrees: '136deg',
  },
  blackWithBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    color: colors.midGrey,
    boxShadow: `inset 0 0 0 2px ${colors.midGrey}`,
  },
  blueLagoonLight: {
    from: '#6793AE',
    to: '#5B9DB4',
    hover: lighten(0.05, '#5B9DB4'),
    degrees: '-125deg',
  },
  blueLagoonDark: {
    from: darken(0.15, '#6793AE'),
    to: darken(0.15, '#5B9DB4'),
    hover: darken(0.05, '#6793AE'),
    degrees: '-125deg',
  },
  retreatSpaLight: {
    from: '#9C747F',
    to: '#8F727C',
    hover: lighten(0.1, '#9C747F'),
    degrees: '-125deg',
  },
  retreatSpaDark: {
    from: darken(0.2, '#9C747F'),
    to: darken(0.2, '#8F727C'),
    hover: darken(0.1, '#8F727C'),
    degrees: '-125deg',
  },
  silicaHotelLight: {
    from: '#A2999C',
    to: '#A0A4B3',
    hover: lighten(0.01, '#A0A4B3'),
    degrees: '-125deg',
  },
  silicaHotelDark: {
    from: darken(0.25, '#A2999C'),
    to: darken(0.25, '#A0A4B3'),
    hover: darken(0.05, '#A2999C'),
    degrees: '-125deg',
  },
  retreatHotelLight: {
    from: '#6F737E',
    to: '#6F747F',
    hover: lighten(0.1, '#6F747F'),
    degrees: '-125deg',
  },
  retreatHotelDark: {
    from: darken(0.2, '#6F737E'),
    to: darken(0.2, '#6F747F'),
    hover: darken(0.05, '#6F737E'),
    degrees: '-125deg',
  },
  transparent: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '-125deg',
  },
  transparentWithBlueBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#3A7E8D',
    boxShadow: 'inset 0 0 0 2px #3A7E8D',
  },
  transparentWithWhiteBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: colors.lightGrey,
    boxShadow: 'inset 0 0 0 2px #F2F2F2',
  },
  transparentWithBlueBorderOnDark: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#91D6E5',
    boxShadow: 'inset 0 0 0 2px #91D6E5',
  },
  highlandWhite: {
    from: rgba('#FFFFFF', 0.95),
    to: rgba('#FFFFFF', 0.95),
    hover: lighten(0.05, '#FFFFFF'),
    degrees: '90deg',
    color: '#67714d',
    borderRadius: 10,
  },
  highlandGreen: {
    from: '#67714d',
    to: '#67714d',
    hover: darken(0.05, '#67714d'),
    degrees: '90deg',
    color: '#ffffff',
    borderRadius: 10,
  },
  highlandSecondary: {
    from: rgba('#a19d82', 0.3),
    to: rgba('#a19d82', 0.3),
    hover: darken(0.1, rgba('#a19d82', 0.3)),
    degrees: '90deg',
    color: '#645f51',
    borderRadius: 10,
  },
  highlandWhiteBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#ffffff',
    boxShadow: 'inset 0 0 0 2px #F2F2F2',
    borderRadius: 10,
  },
  highlandGreenBorder: {
    from: 'transparent',
    to: 'transparent',
    hover: 'transparent',
    degrees: '90deg',
    color: '#645f51',
    boxShadow: `inset 0 0 0 2px ${'#645f51'}`,
    borderRadius: 10,
  },
}

export const BaseButton = styled(({ innerComponent: Link, ...props }) => {
  const cleanProps = omit(props, [
    'bottom',
    'left',
    'right',
    'top',
    'borderRadius',
    'minWidth',
    'maxWidth',
    'paddingSize',
    'transparent',
    'buttonBorderRadius',
  ])
  return cleanProps.to ? (
    <Link role="button" {...cleanProps} />
  ) : (
    <button {...cleanProps} />
  )
})`
  ${({ minWidth = 'initial' }) =>
    map(minWidth, val => `min-width: ${between(val / modularScale, val)};`)};
  ${({ maxWidth = 'initial' }) =>
    map(maxWidth, val => `max-width: ${between(val / modularScale, val)};`)};
  ${({ paddingSize }) =>
    buttonPadding[paddingSize] && buttonPadding[paddingSize]()};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0;
  color: inherit;
  border-radius: ${props => props.borderRadius || 0};
  ${({ preset }) =>
    preset &&
    buttonPresets[preset] &&
    css`
      background: linear-gradient(
        ${buttonPresets[preset].degrees},
        ${buttonPresets[preset].to},
        ${buttonPresets[preset].from}
      );
      color: ${buttonPresets[preset].color || 'inherit'};
      box-shadow: ${buttonPresets[preset].boxShadow || 'none'};
      border-radius: ${buttonPresets[preset].borderRadius || '0'}px;
    `};

  ${({ transparent }) => transparent && 'background transparent'};
  overflow: hidden;
  margin: 0;
  position: relative;

  cursor: pointer;

  ${({ top, theme }) => mt(top, theme)};
  ${({ bottom, theme }) => mb(bottom, theme)};
  ${({ left, theme }) => ml(left, theme)};
  ${({ right, theme }) => mr(right, theme)};

  :disabled {
    background: #f2f2f2;
    color: #454647;
    cursor: default;
  }
`

export const HoverButton = styled(BaseButton)`
  & > * {
    position: relative;
    pointer-events: none;
  }

  &::before {
    --size: 400px;

    content: '';
    position: absolute;
    left: calc(-1 * var(--size) / 2);
    top: calc(-1 * var(--size) / 2);
    width: var(--size);
    height: var(--size);
    transition: opacity 0.5s linear;
    transform: translate(var(--x), var(--y));
    opacity: 0;
    pointer-events: none;

    ${({ preset }) =>
      preset &&
      buttonPresets[preset] &&
      css`
        background: radial-gradient(
          circle closest-side,
          ${buttonPresets[preset].hover},
          transparent
        );
      `};
  }

  ${media.md(css`
    &:hover::before {
      opacity: 1;
      will-change: left, top;
    }
  `)};

  :disabled {
    background: #f2f2f2;
    color: #454647;

    cursor: default;

    &::before {
      --size: 100%;
      left: 0;
      top: 0;

      background: #f0f0f0;
    }
  }
`

export const Text = styled(Type)`
  transition: none;
  margin-bottom: 2px;
  margin-top: 1px;
`
