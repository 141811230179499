import React, { useEffect } from 'react'
import styled from 'styled-components'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { Calendar } from '../../units/Calendar/Calendar'
import { Input } from '../Input/Input'
import { CalendarIcon } from './Icon'

type DatepickerProps = {
  showCalendar?: boolean
  handleDismiss?: () => any
  onFocus?: () => any
  onChange?: (props?: any) => any
  minDate?: any
  maxDate?: any
  dateFormat?: string
  placeholder?: string
  value?: any
  hasError?: boolean
  name?: string
  id?: string
  isDateDisabled?: any
}

const Container = styled.div`
  position: relative;
  width: 100%;
`

const NOOP = () => {}
const DATE_FORMAT = 'dd-MM-yyyy'

export const Datepicker = ({
  showCalendar,
  handleDismiss = NOOP,
  onFocus = NOOP,
  onChange = NOOP,
  minDate,
  maxDate,
  dateFormat = DATE_FORMAT,
  placeholder = 'Pick a date',
  value,
  hasError,
  name,
  id,
  isDateDisabled,
}: DatepickerProps) => {
  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      handleDismiss()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Container>
      <Input
        id={id}
        name={name}
        type="text"
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
        hasError={hasError}
        disableEditing
        autoComplete="off"
      />
      <Calendar
        onChange={date => onChange(formatDateInUTC(new Date(date), dateFormat))}
        isVisible={showCalendar}
        onClose={handleDismiss}
        minDate={minDate}
        maxDate={maxDate}
        isDateDisabled={isDateDisabled}
      />
      <CalendarIcon />
    </Container>
  )
}
