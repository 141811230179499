import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { GetComponentProps } from '../types/custom'
import { media } from '../utils/media'
import { Button } from './Button/Button'

interface ArrowLinkProps {
  arrowSide?: 'left' | 'right'
}

type ElementProps = {
  $arrowSide: 'left' | 'right'
}

const ArrowWrap = styled.span<ElementProps>`
  display: inline-block;
  margin-left: ${({ theme, $arrowSide }) =>
    $arrowSide === 'right' ? theme.spacing[0.5] : 0};
  margin-right: ${({ theme, $arrowSide }) =>
    $arrowSide === 'left' ? theme.spacing[0.5] : 0};
  transition: transform 300ms;
`

const ButtonLink = styled(Button)<ElementProps>`
  color: ${colors.deepBlue};
  padding: ${({ theme }) => `${theme.spacing[0.5]} 0`};
  justify-content: flex-start;
  color: ${({ textColor }) => colors[textColor] || colors.deepBlue};
  overflow: visible;
  white-space: nowrap;

  ${media.md(css<ElementProps>`
    &:hover {
      ${ArrowWrap} {
        transform: translateX(
          ${({ theme, $arrowSide }) =>
            theme.spacing[$arrowSide === 'right' ? 0.5 : -0.5]}
        );
      }
    }
  `)}

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

export const ArrowLink: FC<
  ArrowLinkProps & GetComponentProps<typeof ButtonLink>
> = ({ children, arrowSide = 'right', ...rest }) => (
  <ButtonLink {...rest} transparent noHover $arrowSide={arrowSide}>
    {arrowSide === 'right' && children}
    <ArrowWrap $arrowSide={arrowSide}>
      {arrowSide === 'right' ? '→' : '←'}
    </ArrowWrap>
    {arrowSide === 'left' && children}
  </ButtonLink>
)
