import { createGlobalStyle } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'

export const GlobalThemeVariables = createGlobalStyle`
  :root {
    // Colors
    --global-color-fillPrimary: ${colors.white};
    --global-color-accent: ${colors.deepBlue};

    // Common colored elements
    --global-color-link: ${colors.deepBlue};
    --global-color-listMarker: ${colors.deepBlue};
  }
`
