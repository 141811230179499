import React from 'react'
import { FocusOn } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import type { Variants } from 'framer-motion'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { durations } from '../constants/durations'
import { zIndex } from '../constants/zIndex'
import { Link } from '../elements/Link'
import { Type } from '../elements/Typography/Typography'
import { media } from '../utils/media'
import { VisuallyHidden } from './VisuallyHidden'

type LanguageSelectorProps = {
  position?: 'top' | 'bottom'
  align?: 'left' | 'right'
  isOpen: boolean
  toggleOpen: () => void
  showLabel?: boolean
}

type DialogProps = Pick<LanguageSelectorProps, 'position' | 'align'> & {
  $isOpen: boolean
}

const AVAILABLE_LANGUAGES = [
  {
    code: 'en',
    name: 'English',
    path: '/',
  },
  {
    code: 'is',
    name: 'Íslenska',
    path: '/is',
  },
]

const menu: Variants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
}

const Globe = () => (
  <svg
    role="presentation"
    viewBox="0 0 20 20"
    width="20"
    height="20"
    fill="none"
    stroke="#464647"
    strokeWidth="1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.89712 19.1487c5.10968 0 9.25158-4.1419 9.25158-9.25158 0-5.10968-4.1419-9.25161-9.25158-9.25161C4.78744.6455.64551 4.78744.64551 9.89712c0 5.10968 4.14193 9.25158 9.25161 9.25158Z"></path>
    <path d="M9.78034 19.1487c2.24516 0 4.06456-4.1419 4.06456-9.25158 0-5.10968-1.8194-9.25161-4.06456-9.25161-2.24452 0-4.06452 4.14193-4.06452 9.25161 0 5.10968 1.82 9.25158 4.06452 9.25158Z"></path>
    <path d="M17.1742 3.72211a18.95472 18.95472 0 0 1-7.27739 1.23871A19.66477 19.66477 0 0 1 2.90326 3.8705m0 12.3161a19.3556 19.3556 0 0 1 6.99355-1.1355c2.21869-.0387 4.42839.2865 6.54189.9613M.36133 10.0447H19.4775M9.91617.4834V19.606"></path>
  </svg>
)

const Container = styled.div`
  position: relative;
  display: inline-flex;
`

const Selector = styled.button`
  position: relative;
  display: flex;
  cursor: pointer;
  color: ${colors.dark};

  &::after {
    content: '';
    position: absolute;
    inset: -5px;
  }

  ${media.md(css`
    margin-top: 0;
  `)};
`

const SelectorGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[0.5]};
`

const Dialog = styled(motion.ul)<DialogProps>`
  background: ${colors.white};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: ${({ theme }) => theme.spacing[2.5]};
  left: 0;
  text-align: center;
  z-index: ${zIndex.aboveMenu};
  display: none;

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: auto;
      bottom: ${({ theme }) => theme.spacing[2.5]};
    `}

  ${({ align }) =>
    align === 'right' &&
    css`
      left: auto;
      right: 0;
    `}

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: block;
    `};
`

const StyledLink = styled(Link)`
  display: grid;
  padding: ${({ theme }) => `${theme.spacing[1.5]} ${theme.spacing[2.5]}`};
  transition: background ${durations.short}ms;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  `)}

  &:hover {
    color: ${colors.deepBlue};
    background: ${colors.lightGrey};
  }
`

export const LanguageSelector = ({
  position = 'top',
  align = 'left',
  isOpen,
  toggleOpen,
  showLabel,
}: LanguageSelectorProps) => {
  const { t, i18n } = useTranslation()

  return (
    <Container>
      <>
        <Selector onClick={toggleOpen} role="button">
          <VisuallyHidden>{t('language')}</VisuallyHidden>
          <SelectorGroup>
            <Globe />
            {showLabel && <Type preset="labelSmall">{t('language')}</Type>}
          </SelectorGroup>
        </Selector>
        <FocusOn
          enabled={isOpen}
          autoFocus={false}
          returnFocus
          onClickOutside={toggleOpen}
          onEscapeKey={toggleOpen}
          onDeactivation={toggleOpen}
        >
          <Dialog
            $isOpen={isOpen}
            aria-expanded={isOpen}
            variants={menu}
            initial="closed"
            exit="closed"
            animate={isOpen ? 'open' : 'closed'}
            position={position}
            align={align}
          >
            {AVAILABLE_LANGUAGES.map(item => {
              const isActive = item.code === i18n.language
              return (
                <li key={item.name} {...item}>
                  <StyledLink to={item.path}>
                    <Type
                      size={{ xs: 16, md: 16 }}
                      weight="medium"
                      color={isActive ? colors.deepBlue : colors.dark}
                    >
                      {item.name}
                    </Type>
                  </StyledLink>
                </li>
              )
            })}
          </Dialog>
        </FocusOn>
      </>
    </Container>
  )
}
