import React, { createContext } from 'react'

const context = createContext(null)

export const CorrelationIdContext = context
export const CorrelationIdProvider = context.Provider
export const CorrelationIdConsumer = context.Consumer

const CorrelationIdService = ({ correlationId, children }) => {
  return (
    <CorrelationIdProvider value={{ correlationId }}>
      {children}
    </CorrelationIdProvider>
  )
}

export default CorrelationIdService
