import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { zIndex as zIndexUtil } from '../../constants/zIndex'
import { media, mediaMax } from '../../utils/media'
import { Link } from '../Link'
import { lockedScrollOffset } from './lockedScrollOffset'

type FabProps = {
  bottom?: string
  $zIndex?: string | number
  mobile?: boolean
  desktop?: boolean
}

const isIphoneX = () =>
  typeof window !== 'undefined' &&
  /iphone/i.test(window.navigator.userAgent) &&
  window.screen.height === 812

// Just a simple wrapper with bottom position for now.
const Fab = styled.button<FabProps>`
  background: ${colors.white};
  border-radius: 50%;
  border: 0;
  ${({ bottom = '47px' }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  height: 47px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  user-select: none;
  width: 47px;
  z-index: ${({ $zIndex }) => ($zIndex ? $zIndex : zIndexUtil.aboveMenu)};

  ${
    isIphoneX() &&
    css`
    bottom: 4rem;
  `
  };

  ${props =>
    props.mobile &&
    media.md(css`
      display: none;
    `)};

  ${props =>
    props.desktop &&
    mediaMax.md(css`
      display: none;
    `)};

  /* Use props to re-evaluate so we end up with the right scrollbar width. */
  ${() => lockedScrollOffset()}
`

const Line = styled.div`
  background: #454647;
  height: 2px;
  margin: auto;
  width: 18px;
`

const TopLine = styled(Line)`
  transform: translateY(1px) rotateZ(-45deg);
`

const BottomLine = styled(Line)`
  transform: translateY(-1px) rotateZ(45deg);
`

const Icon = styled.div`
  margin: auto;
  overflow: visible;
  transform: rotateZ(-90deg);
  transition: transform ${durations.short}ms ease-out;

  ${Fab}:hover & {
    transform: none;
  }
`

type CloseFabProps = {
  to?: unknown
  onClick: () => void
  bottom?: string
  zIndex?: string | number
}

export const CloseFab = ({ to, onClick, bottom, zIndex }: CloseFabProps) => (
  <Fab
    as={to ? Link : 'button'}
    to={to}
    onClick={onClick}
    $zIndex={zIndex}
    aria-label="Close Navigation"
    bottom={bottom}
  >
    <Icon>
      <TopLine />
      <BottomLine />
    </Icon>
  </Fab>
)
